import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import { assert, assertHasProperties } from 'common/assertions';

import StorytellerUtils from 'lib/StorytellerUtils';
import { isFlexibleStory } from 'lib/FlexibleLayoutUtils';

import { AssetSelectorProps } from '../types';
import { AssetSelectorBlockComponent } from 'types';

import ClassicLayoutsAssetSelectorInsertButton from './ClassicLayoutsAssetSelectorInsertButton';
import '../shared/componentBase';
import AssetSelectorInsertButton from './AssetSelectorInsertButton';

$.fn.componentAssetSelector = componentAssetSelector;

export default function componentAssetSelector(props: AssetSelectorProps): JQuery {
  props = _.extend({}, props, { editButtonSupported: false });

  const { componentData } = props;

  assertHasProperties(componentData, 'type');
  assert(
    componentData.type === 'assetSelector',
    `componentAssetSelector: Unsupported component type ${componentData.type}`
  );

  if (this.children().length === 0) {
    _renderSelector(this, componentData);
  }

  this.componentBase(props);

  return this;
}

function _renderSelector($element: JQuery, componentData: AssetSelectorBlockComponent) {
  const classes = [StorytellerUtils.typeToClassesForComponentType(componentData.type)];

  if (isFlexibleStory()) {
    ReactDOM.render(<AssetSelectorInsertButton />, $element[0]);

    classes.push('no-asset-insert');
    classes.push('component-asset-selector-flexible');
  } else {
    ReactDOM.render(<ClassicLayoutsAssetSelectorInsertButton />, $element[0]);
  }

  $element.addClass(classes);
}
