import React, { FunctionComponent } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { sharedFlexRGLProps } from 'lib/FlexibleLayoutUtils';
import ViewBlockItem from './ViewBlockItem';
import BlockItemModel from '../../editor/model/BlockItemModel';
import { BlockComponent } from 'types';
import { GlobalFilters, GlobalParameterOverrides } from 'types';

export interface ViewBlockSectionProps {
  components: BlockComponent[];
  blockId: string;
  globalFilters: GlobalFilters;
  parameterOverrides: GlobalParameterOverrides;
}

// @ts-ignore
const ResponsiveReactGridLayout = new WidthProvider(Responsive);

const ViewBlockSection: FunctionComponent<ViewBlockSectionProps> = (props) => {
  const { blockId, components, globalFilters, parameterOverrides } = props;

  const generateBlockItemModels = (blockItemData: BlockComponent[]): BlockItemModel[] => {
    return blockItemData.map((blockItem, index: number) => {
      return new BlockItemModel(blockItem.layout, blockItem.type, blockItem.value, index, blockId);
    });
  };

  // Applies default layouts and ids to components
  const blockItems = generateBlockItemModels(components);

  const renderBlockSectionItem = () => {
    // static must be set at the end of object because JS is dumb
    const dataGridLayout = (blockItem: BlockItemModel) => ({
      ...blockItem.layout,
      isDraggable: false,
      static: true
    });

    return blockItems.map((blockItem: BlockItemModel) => {
      return (
        <div key={blockItem.layout.i} data-grid={dataGridLayout(blockItem)}>
          <ViewBlockItem
            component={{
              id: blockItem.id,
              type: blockItem.type,
              value: blockItem.value,
              layout: blockItem.layout
            }}
            globalFilters={globalFilters}
            parameterOverrides={parameterOverrides}
          />
        </div>
      );
    });
  };

  return (
    <div className="block-content">
      <ResponsiveReactGridLayout
        className={sharedFlexRGLProps.className}
        cols={sharedFlexRGLProps.cols}
        rowHeight={sharedFlexRGLProps.rowHeight}
        isDraggable={false}
        isResizable={false}
        isDroppable={false}
        containerPadding={sharedFlexRGLProps.containerPadding}
        margin={sharedFlexRGLProps.margin}
        // setting this to false forces D3 to not rely on `transform` for positioning.
        // if we don't set it to false, the location of `d3.mouse` will somehow return
        // the wrong cursor location.
        // see https://github.com/elastic/kibana/issues/16870 for a similar issue.
        // useCSSTransforms={false}
      >
        {renderBlockSectionItem()}
      </ResponsiveReactGridLayout>
    </div>
  );
};

export default ViewBlockSection;
