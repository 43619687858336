import $ from 'jquery';
import _ from 'lodash';

import { assert, assertHasProperty } from 'common/assertions';

import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';
import { prepareVifForRender } from 'lib/VifUtils';
import { flyoutRenderer } from 'lib/components/FlyoutRenderer';

import './shared/componentBase';
import { FlyoutEvent, MapProps } from './types';
import { MapBlockComponent } from 'types';

$.fn.componentSocrataVisualizationMap = componentSocrataVisualizationMap;

/*
  Component format:
  {
   type: "socrata.visualization.map",
   value: {
     vif: <vif object>
   }
  }
*/

export default function componentSocrataVisualizationMap(props: MapProps) {
  props = _.extend({}, props, {
    isFilterableAsset: true,
    resizeSupported: true,
    resizeOptions: {
      minHeight: Constants.MINIMUM_COMPONENT_HEIGHTS_PX.VISUALIZATION
    }
  });

  const $this = $(this);
  const { componentData, editMode } = props;

  assertHasProperty(props, 'componentData.type');
  assert(
    props.componentData.type === 'socrata.visualization.map',
    `componentSocrataVisualizationMap: Unsupported component type ${props.componentData.type}`
  );

  if ($this.children().length === 0) {
    _renderTemplate($this, componentData, props);
  }

  _updateVisualization($this, props);
  $this.componentBase(props);

  return $this;
}

function _renderTemplate($element: JQuery, componentData: MapBlockComponent, props: MapProps) {
  assertHasProperty(componentData, 'type');

  const classes = StorytellerUtils.typeToClassesForComponentType(componentData.type);
  const $componentContent = $('<div>', { class: 'component-content' });
  const flyoutEvent = 'SOCRATA_VISUALIZATION_FLYOUT';

  $element
    .addClass(classes)
    .on('destroy', () => {
      $componentContent.triggerHandler('destroy');
    })
    .on(flyoutEvent, (e: JQuery.Event) => {
      const event = e as FlyoutEvent;
      // TODO: Flyouts will/should be handled on the mouse interaction story.
      const payload = event.originalEvent?.detail;

      if (payload !== null) {
        flyoutRenderer.render(payload);
      } else {
        flyoutRenderer.clear();
      }
    });

  $element.append($componentContent);
}

function _updateVisualization($element: JQuery, props: MapProps) {
  assertHasProperty(props, 'componentData.value.vif');

  const { componentData, editMode, additionalFilters = {}, parameterOverrides = {} } = props;
  const $componentContent = $element.find('.component-content');
  const { vif } = componentData.value;

  prepareVifForRender(
    $element,
    vif,
    additionalFilters,
    (updatedVif) => {
      // If it looks like the map has already been rendered once, we can just update it instead of
      // destroying it and rendering from scratch
      const isMap = _.isEqual(_.get(updatedVif, 'series[0].type'), 'map');
      if (isMap && $componentContent.find('canvas').length > 0) {
        $componentContent[0].dispatchEvent(
          new CustomEvent('SOCRATA_VISUALIZATION_RENDER_VIF', { detail: updatedVif })
        );
        // Otherwise, we should destroy whatever used to be in the component and
        // create a new map in its place.
      } else {
        // Use triggerHandler since we don't want this to bubble
        $componentContent.triggerHandler('SOCRATA_VISUALIZATION_DESTROY');
        $componentContent.socrataUnifiedMap(updatedVif, {
          displayFilterBar: true,
          toggleMapLayersInternally: true
        });
      }
    },
    parameterOverrides
  );
}
