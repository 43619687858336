import React, { useState, useEffect } from 'react';

import './styles/PdfCreatedAtItem.scss';

import { fetchAllSubscriptionsForAsset } from 'common/notifications/api/ScreenshotSubscriptionsAPI';
import Environment from 'StorytellerEnvironment';

interface User {
  id: string;
}

interface StoryData {
  uid: string;
}

interface Props {
  user: User | undefined;
}

const PdfCreatedAtItem: React.FC<Props> = ({ user }) => {
  const [createdAt, setCreatedAt] = useState<string | undefined>(undefined);

  const calculateCurrentTimeStamp = (timeZone?: string) => {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timeZone || 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short'
    };
    const now = new Date();
    const formattedDate = now.toLocaleString('en-US', options);
    setCreatedAt(formattedDate);
  };

  const getSubscriptionAndParseCreatedAt = async () => {
    if (user) {
      try {
        const subscriptions = await fetchAllSubscriptionsForAsset(Environment.STORY_UID!).then(
          (res) => res.data
        );
        const currentUserSubscription = subscriptions.filter(
          (subscription) => subscription.subscriber_uid === user.id
        )[0];
        if (currentUserSubscription) {
          calculateCurrentTimeStamp(currentUserSubscription.first_occurrence_timezone);
        } else {
          throw new Error('No Subscription for current user.');
        }
      } catch (e) {
        calculateCurrentTimeStamp();
      }
    }
  };

  useEffect(() => {
    getSubscriptionAndParseCreatedAt();
  });

  return <span>{createdAt}</span>;
};

export default PdfCreatedAtItem;
