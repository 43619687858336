import React, { Component, ReactElement } from 'react';
import { ForgeIcon } from '@tylertech/forge-react';

import { PicklistOption } from 'common/components/Picklist';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';

import { COMPONENT_TYPE_ASSET_SELECTOR } from 'types';
import { COMPONENT_ACTION_TYPES } from 'lib/Constants';
import Actions from 'Actions';
import Environment from 'StorytellerEnvironment';
import { dispatcher } from 'Dispatcher';
import { actionComponentStore } from 'editor/stores/ActionComponentStore';
import { storyStore } from 'editor/stores/StoryStore';

export interface ComponentEditMenuProps {
  blockId: string;
  componentIndex: number;
  componentData: {
    type: string;
  };
  editButtonSupported: boolean;
  updateTableButtonSupported: boolean;
}

/**
 * Renders a block component menu that dispatches several component-level
 * actions, such as editing the component, and moving/swapping components.
 *
 * This can be located in the UI by dropping a media block in the story, configuring
 * it, and then mousing over the result. Buttons anchored on the top-right of the
 * component container will appear.
 */
class ComponentEditMenu extends Component<ComponentEditMenuProps> {
  options = (): PicklistOption[] => {
    const o = [
      {
        title: I18n.t('editor.components.edit_controls.move'),
        group: I18n.t('editor.components.edit_controls.groups.actions'),
        icon: 'cursor_move',
        value: 'move',
        render: this.renderKebabPicklistOption
      }
    ];

    if (this.isNotAssetSelector()) {
      o.push({
        title: I18n.t('editor.components.edit_controls.copy'),
        group: I18n.t('editor.components.edit_controls.groups.actions'),
        icon: 'content_copy',
        value: 'copy',
        render: this.renderKebabPicklistOption
      });
    }

    if (this.usePasteButton()) {
      o.push({
        title: I18n.t('editor.components.edit_controls.paste'),
        group: I18n.t('editor.components.edit_controls.groups.actions'),
        icon: 'content_paste',
        value: 'paste',
        render: this.renderKebabPicklistOption
      });
    }

    if (this.isNotAssetSelector()) {
      o.push({
        title: I18n.t('editor.components.edit_controls.reset'),
        group: I18n.t('editor.components.edit_controls.groups.actions'),
        icon: 'close',
        value: 'reset',
        render: this.renderKebabPicklistOption
      });
    }

    return o;
  };

  usePasteButton = (): boolean => {
    const { blockId } = this.props;
    try {
      return !storyStore.isBlockRestrictedToMeasureCardsOnly(blockId);
    } catch (e) {
      // This mainly happens in tests, where we render components without the whole story.
      return true;
    }
  };

  isNotAssetSelector = (): boolean => {
    const { componentData } = this.props;
    return componentData.type !== COMPONENT_TYPE_ASSET_SELECTOR;
  };

  clickEditButton = (): void => {
    const { blockId, componentIndex } = this.props;

    dispatcher.dispatch({
      action: Actions.ASSET_SELECTOR_EDIT_EXISTING_ASSET_EMBED,
      blockId,
      componentIndex
    });
  };

  clickUpdateTableButton = (): void => {
    const { blockId } = this.props;
    const storyUid = Environment.STORY_UID;

    dispatcher.dispatch({
      action: Actions.STORY_INSERT_TABLE_OF_CONTENTS,
      storyUid: storyUid,
      blockWithToCId: blockId
    });
  };

  selectKebabPicklistItem = (option: PicklistOption): void => {
    const { blockId, componentIndex } = this.props;

    switch (option.value) {
      case 'reset':
        dispatcher.dispatch({
          action: Actions.RESET_COMPONENT,
          blockId,
          componentIndex
        });
        break;
      case 'move':
        dispatcher.dispatch({
          action: Actions.ACTION_COMPONENT_START,
          blockId,
          componentIndex,
          actionType: COMPONENT_ACTION_TYPES.MOVE
        });
        break;
      case 'copy':
        dispatcher.dispatch({
          action: Actions.COPY_COMPONENT,
          blockId,
          componentIndex
        });
        break;
      case 'paste':
        dispatcher.dispatch({
          action: Actions.PASTE_COMPONENT,
          blockId,
          componentIndex
        });
        break;
      default:
        break;
    }
  };

  renderKebabPicklistOption = (option: PicklistOption): ReactElement => {
    return (
      <div>
        <ForgeIcon name={option.icon} />
        <span>{option.title}</span>
      </div>
    );
  };

  renderKebabPicklist = (): ReactElement => {
    const attributes = {
      onSelection: this.selectKebabPicklistItem,
      options: this.options(),
      placeholder: this.renderKebabButton,
      skipPositionPicklist: true
    };

    return <Dropdown {...attributes} />;
  };

  renderKebabButton = (): ReactElement => {
    const attributes = {
      className: 'component-edit-controls-btn component-edit-controls-kebab-btn'
    };
    return (
      <button {...attributes} data-testid="component-edit-controls-kebab-btn">
        <span className="icon-kebab" />
      </button>
    );
  };

  renderEditButton = (): ReactElement => {
    return (
      <button
        className="component-edit-controls-btn component-edit-controls-edit-btn"
        onClick={this.clickEditButton}
      >
        {I18n.t('editor.components.edit_controls.button')}
      </button>
    );
  };

  renderUpdateTableButton = (): ReactElement => {
    return (
      <button
        className="component-edit-controls-btn component-edit-controls-update-table-btn"
        onClick={this.clickUpdateTableButton}
      >
        {I18n.t('editor.components.table_of_contents.update_table')}
      </button>
    );
  };

  render(): ReactElement | null {
    const { componentData, editButtonSupported, updateTableButtonSupported } = this.props;

    // NOTE: The same criteria apply for a component's ability to be reset, so
    // the ActionComponentStore check is doing double duty.
    const kebabEnabled = actionComponentStore.isComponentValidMoveSource(componentData.type);
    const kebabButton = kebabEnabled ? this.renderKebabPicklist() : null;
    const editButton = editButtonSupported ? this.renderEditButton() : null;
    const updateTableButton = updateTableButtonSupported ? this.renderUpdateTableButton() : null;

    if (!editButton && !updateTableButton && !kebabButton) {
      return null;
    }

    return (
      <div className="component-edit-controls" data-testid="component-edit-controls">
        {editButton}
        {updateTableButton}
        {kebabButton}
      </div>
    );
  }
}

export default ComponentEditMenu;
