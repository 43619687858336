import $ from 'jquery';
import _ from 'lodash';

import { assert, assertHasProperty } from 'common/assertions';
import 'common/visualizations/jquery';

import { TableProps } from './types';
import './shared/componentBase';
import { getDefaultVisualizationProps } from 'lib/VifUtils';
import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';
import { flyoutRenderer } from 'lib/components/FlyoutRenderer';
import { prepareVifForRender } from 'lib/VifUtils';
import { migrateInSituTables } from 'common/visualizations/helpers/VifHelpers';
import {
  shouldEnableLookupLog,
  markLookupLogAttached,
  getDatasetIdFromGlobalFilterBar,
  sendLookupToolLogRequest
} from 'lib/LookupToolLog';

$.fn.componentSocrataVisualizationTable = componentSocrataVisualizationTable;

// @ts-ignore
export default async function componentSocrataVisualizationTable(props: TableProps): JQuery {
  props = _.extend({}, props, {
    resizeSupported: true,
    resizeOptions: {
      minHeight: Constants.MINIMUM_COMPONENT_HEIGHTS_PX.VISUALIZATION
    },
    defaultHeight: Constants.DEFAULT_TABLE_HEIGHT,
    isFilterableAsset: true
  });

  const $this = $(this);
  const { componentData, editMode } = props;

  assertHasProperty(componentData, 'type');
  assert(
    componentData.type === 'socrata.visualization.table' ||
      componentData.type === 'socrata.visualization.agTable',
    `componentSocrataVisualizationTable: Unsupported component type ${componentData.type}`
  );

  if (componentData.type === 'socrata.visualization.table') {
    // update component data with ag type to render the correct template and apply classnames
    _.set(componentData, 'type', 'socrata.visualization.agTable');

    const originalVif = _.cloneDeep(_.get(componentData, 'value.vif'));

    // migrate vif from type table to type AgTable
    const migratedTableVif = await migrateInSituTables(originalVif);
    _.set(componentData, 'value.vif', migratedTableVif);
  }

  if ($this.children().length > 0) {
    const componentTypeClass = StorytellerUtils.typeToClassesForComponentType(componentData.type);

    const hasComponentTypeChanged = $this.hasClass(componentTypeClass) === false;

    if (hasComponentTypeChanged) {
      _destroyPreviousTable($this);
      _renderTemplate($this, props);
    }
  } else {
    _renderTemplate($this, props);
  }

  _updateVisualization($this, props);

  $this.componentBase(props);

  return $this;
}

function _renderTemplate($element: JQuery, props: TableProps): void {
  const { componentData } = props;
  const $componentContent = $('<div>', { class: 'component-content' });

  assertHasProperty(componentData, 'type');

  $element
    .addClass(StorytellerUtils.typeToClassesForComponentType(componentData.type))
    // Pass on the destroy event to plugin.
    .on('destroy', () => {
      $componentContent.triggerHandler('destroy');
    })
    .on('SOCRATA_VISUALIZATION_FLYOUT', (event) => {
      const payload = _.get(event, 'originalEvent.detail');

      if (payload !== null) {
        flyoutRenderer.render(payload);
      } else {
        flyoutRenderer.clear();
      }
    });

  $element.append($componentContent);
}

function _destroyPreviousTable($element: JQuery): void {
  const $componentContent = $element.find('.component-content');

  $componentContent.triggerHandler('SOCRATA_VISUALIZATION_DESTROY');
  $element.empty();
  $element.removeClass();
  $element.addClass('component');
}

function _updateVisualization($element: JQuery, props: TableProps): void {
  const { componentData, additionalFilters = {}, parameterOverrides = {} } = props;
  const $componentContent = $element.find('.component-content');

  assertHasProperty(componentData, 'value.vif');

  // Determine if we should enable lookup log
  const datasetUid = _.get(componentData, 'value.vif.series[0].dataSource.datasetUid');
  let additionalOptions = {};
  if (shouldEnableLookupLog() && !!datasetUid && getDatasetIdFromGlobalFilterBar() === datasetUid) {
    // add callback as an option if we need to log lookup tool usage
    additionalOptions = { lookupLogCallback: sendLookupToolLogRequest };
    markLookupLogAttached();
  }

  const filterBarOptions = _.merge(getDefaultVisualizationProps(), additionalOptions);

  const { vif } = componentData.value;

  prepareVifForRender(
    $element,
    vif,
    additionalFilters,
    (updatedVif) => {
      if ($element.find('.socrata-paginated-table').length > 0) {
        const renderVifEvent = new window.CustomEvent('SOCRATA_VISUALIZATION_RENDER_VIF', {
          detail: updatedVif,
          bubbles: true
        });
        $componentContent[0].dispatchEvent(renderVifEvent);
      } else {
        $componentContent.socrataAgTable(updatedVif, filterBarOptions);
      }
    },
    parameterOverrides
  );
}
