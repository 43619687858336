import _ from 'lodash';

import { ClientContextVariable } from 'common/types/clientContextVariable';
import type { ParameterOverrides } from 'common/components/SingleSourceFilterBar/types';

export const mergeParameterOverrides = (
  parameterOverrides: ParameterOverrides,
  availableParameters: ClientContextVariable[] = []
): ClientContextVariable[] => {
  if (parameterOverrides instanceof Map) {
    return availableParameters.map((param) => {
      const override = parameterOverrides.get(param.name + '.' + param.viewId);
      if (override) {
        return { ...param, overrideValue: override.paramOverrideValue };
      }
      return param;
    });
  }

  return availableParameters;
};
