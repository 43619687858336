import Actions from 'Actions';
import { FluxPayload, COMPONENT_TYPE_VIZ_CANVAS } from 'types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep, get } from 'lodash';

import Environment from 'StorytellerEnvironment';
import { StorytellerState } from 'store/StorytellerReduxStore';
import { storyReducerStoriesSelector, storyReducerBlocksSelector } from 'store/TopLevelSelector';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';

/**
 * Actions that will be handled by multiple reducers should go here.
 */

export const createStory = createAction<FluxPayload>(Actions.STORY_CREATE);
export const saveStory = createAction<FluxPayload>(Actions.STORY_SAVED);

export const addFilterParameterConfiguration = createAction<FluxPayload>(
  Actions.FILTER_PARAMETER_CONFIGURATION_ADDED
);
export const deleteFilterParameterConfiguration = createAction<FluxPayload>(
  Actions.FILTER_PARAMETER_CONFIGURATION_DELETED
);
export const updateFilterParameterConfiguration = createAction<FluxPayload>(
  Actions.FILTER_PARAMETER_CONFIGURATION_UPDATED
);
export const updateAllFilterParameterConfigurations = createAction<FluxPayload>(
  Actions.ALL_FILTER_PARAMETER_CONFIGURATIONS_UPDATED
);
export const prepareStoryForTemplate = createAction<FluxPayload>(Actions.STORY_PREPARE_FOR_TEMPLATE);
export const replaceStoryFromJsonImport = createAction<FluxPayload>(Actions.STORY_REPLACE_FROM_JSON_IMPORT);
export const replaceStoryFromTemplate = createAction<FluxPayload>(Actions.STORY_REPLACE_FROM_TEMPLATE);

export const removeStoryDataSource = createAction<FluxPayload>(Actions.STORY_DATA_SOURCE_REMOVED);
export const updateStoryDataSource = createAction<FluxPayload>(Actions.STORY_DATA_SOURCE_UPDATED);
export const updateParameterOverrides = createAction<FluxPayload>(Actions.PARAMETER_OVERRIDES_UPDATED);

export const removeGlobalFilter = createAction<void>(Actions.GLOBAL_FILTER_REMOVED);

/**
 * Async thunks
 */

export const refreshRequiredDataSources = createAsyncThunk<
  // Return type
  { requiredDataSources: string[] },
  // Payload type
  void,
  // thunkAPI type
  {
    state: StorytellerState;
  }
>(Actions.REFRESH_REQUIRED_DATA_SOURCES, async (payload, thunkAPI) => {
  const stories = storyReducerStoriesSelector(thunkAPI.getState()) || {};
  const requiredDataSources: string[] = [];

  if (Environment.STORY_UID) {
    const { blockIds = [] } = stories[Environment.STORY_UID];
    const blocks = storyReducerBlocksSelector(thunkAPI.getState()) || {};
    const vizCanDatasets: string[] = [];

    blockIds.forEach((blockId) => {
      const block = blocks[blockId] || {};
      const { components = [] } = block;
      components.forEach((component) => {
        // If there is VIF available, add to requiredDataSources
        const vif = get(component, 'value.vif');
        if (vif) {
          const migratedVif = migrateVif(cloneDeep(vif));
          const datasetUid: string = get(migratedVif, 'series[0].dataSource.datasetUid');
          if (datasetUid && !requiredDataSources.includes(datasetUid)) {
            requiredDataSources.push(datasetUid);
          }
        }

        // If there is VizCan, get its dataset into vizCanDatasets
        if (component.type === COMPONENT_TYPE_VIZ_CANVAS) {
          // For vizCan, we don't have vif, so we need to get it from value
          const { datasetUid }: { datasetUid: string } = component.value?.dataset ?? {};
          if (datasetUid && !vizCanDatasets.includes(datasetUid)) {
            vizCanDatasets.push(datasetUid);
          }
        }
      });
    });

    // For VizCan datasets, get its VIF from api/views and add to requiredDataSources
    if (vizCanDatasets.length > 0) {
      const metadataProviderPromises = vizCanDatasets.map((datasetUid) => {
        return new MetadataProvider({ datasetUid }, true).getDatasetMetadata();
      });
      const views = await Promise.all(metadataProviderPromises);
      views.forEach((view) => {
        const vif = get(view, 'displayFormat.visualizationCanvasMetadata.vifs[0]');
        if (vif) {
          const migratedVif = migrateVif(cloneDeep(vif));
          const datasetUid = get(migratedVif, 'series[0].dataSource.datasetUid');
          if (datasetUid && !requiredDataSources.includes(datasetUid)) {
            requiredDataSources.push(datasetUid);
          }
        }
      });
    }
  }

  return { requiredDataSources };
});
