import _ from 'lodash';
import { Dispatcher } from 'flux';

import { FluxPayload } from 'types';
import Store from 'Store';
import Actions from 'Actions';
import StorytellerUtils from 'lib/StorytellerUtils';
import { isComponentTypeMovable } from '../../lib/StoryHelper';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';
import { actionComponentStart, actionComponentCancel, ActionComponentPayload } from 'store/reducers/ActionComponentReducer';
import { selectors } from 'store/selectors/ActionComponentSelectors';

export const actionComponentStore = StorytellerUtils.export(
  // @ts-ignore
  new ActionComponentStore(),
  'storyteller.actionComponentStore'
);

/**
 * Handles inter-component actions:
 * - COMPONENT_ACTION_TYPES.MOVE: Swapping two components. This can happen from within
 *   the same block or across blocks. Components are identified by blockId and componentIndex.
 * - COMPONENT_ACTION_TYPES.SELECT_DATASET: Select the data source for the global filter bar.
 */
export default function ActionComponentStore(dispatcher?: Dispatcher<any>): any {
  // @ts-ignore: TODO. Not sure how to express this kind of prototype inheritance in TS.
  _.extend(this, new Store(dispatcher));

  this.register((payload: FluxPayload) => {
    switch (payload.action) {
      case Actions.ACTION_COMPONENT_START:
        StorytellerReduxStore.dispatch(actionComponentStart(payload as ActionComponentPayload));
        this._emitChange();
        break;
      case Actions.ACTION_COMPONENT_CANCEL:
        StorytellerReduxStore.dispatch(actionComponentCancel());
        this._emitChange();
        break;
      default:
        break;
    }
  });

  this.isUserChoosingMoveDestination = (): boolean => selectors.isUserChoosingMoveDestination(StorytellerReduxStore.getState());

  this.isComponentValidMoveDestination = (blockId: string, componentIndex: number): boolean => {
    return selectors.isComponentValidMoveDestination(blockId, componentIndex, StorytellerReduxStore.getState());
  };

  this.isComponentValidMoveSource = (componentType: string): boolean => {
    return isComponentTypeMovable(componentType);
  };

  this.isComponentBeingMoved = (blockId: string, componentIndex: number): boolean => {
    return selectors.isComponentBeingMoved(blockId, componentIndex, StorytellerReduxStore.getState());
  };

  this.isActionComponentStoreActive = (): boolean => {
    return selectors.isActionComponentStoreActive(StorytellerReduxStore.getState());
  };
}
