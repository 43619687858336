import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import { assert, assertHasProperties } from 'common/assertions';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';

import Actions from 'Actions';
import { dispatcher } from 'Dispatcher';

import { COMPONENT_ACTION_TYPES } from 'lib/Constants';
import { isFlexibleStory } from 'lib/FlexibleLayoutUtils';
import { GlobalFiltersUpdatedEvent, RemoveComponentDetails, COMPONENT_TYPE_GLOBAL_FILTER } from 'types';
import { ComponentProps } from '../types';
import './componentResizable';
import './withLayoutHeightFromComponentData';
import ComponentEditMenu from './ComponentEditMenu';
import ComponentActionOverlay from './componentActionOverlay';
import ComponentDraggerControl from 'editor/components/ComponentDraggerControl';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';
import { selectors } from 'store/selectors/ActionComponentSelectors';

const isTylerForgeVizLayoutEnabled = FeatureFlags.value('enable_forge_layout_for_viz');

// TODO EN-41251: We should make it possible to do component renderers in React
// so we don't need to decide whether to update ourselves.
$.fn.componentBase = componentBase;

/*
 * Supported props:
 *
 * componentData (required)
 * theme (required)
 * blockId (required)
 * componentIndex (required)
 *
 * isComponentValidMoveDestination
 * isUserChoosingMoveDestination
 * isComponentBeingMoved
 *
 * editMode
 * editButtonSupported
 * resizeSupported
 * resizeOptions
 * defaultHeight
 * firstRenderCallback
 * dataChangedCallback
 */

export interface ComponentBaseType extends JQuery {
  updateDataSources: (element: JQuery, datasets: string[]) => void;
}

export default function componentBase(props: ComponentProps): ComponentBaseType {
  // eslint-disable-next-line prefer-rest-params
  assert(arguments.length === 1, 'Invalid invocation of componentBase');

  assertHasProperties(props, 'componentData', 'theme', 'blockId', 'componentIndex');

  props = _.extend(
    {
      // Note that it isn't possible to switch between edit
      // and non-edit modes (not that it would be hard to add,
      // we just don't need it now).
      editMode: false,

      editButtonSupported: true,

      isFilterableAsset: false,
      dataSources: [],

      resizeSupported: false,
      resizeOptions: {},

      // If not blank, establishes a default height for the component.
      // It is used if value.layout.height is not defined in componentData.
      defaultHeight: undefined,

      isComponentValidMoveDestination: false,
      isUserChoosingMoveDestination: false,
      isComponentBeingMoved: false,

      dataChangedCallback: _.noop,
      firstRenderCallback: _.noop
    },
    props
  );

  const {
    blockId,
    componentIndex,
    componentData,
    firstRenderCallback,
    dataChangedCallback,
    editMode,
    editButtonSupported,
    resizeSupported,
    resizeOptions,
    defaultHeight,
    isUserChoosingMoveDestination,
    isFilterableAsset,
    dataSources
  } = props;

  const currentData = this.data('component-rendered-data');
  const isTableOfContentsComponent = componentData.type === 'html.tableOfContents';
  const updateTableButtonSupported = isTableOfContentsComponent;
  this.data('props', props);

  $(document.body).removeClass('action-overlay-active');

  this.toggleClass('editing', editMode);

  // TODO: EN-46001 - Disable withLayoutHeightFromComponentData in View Mode too
  // as heights will be determined by RGL height
  if (!isFlexibleStory()) {
    this.withLayoutHeightFromComponentData(componentData, defaultHeight);
  }

  this.updateDataSources = (element: JQuery, datasets: string[]): void => {
    renderFilterIcon(element, props, datasets);
  };

  if (editMode) {
    renderMoveActionOverlay(this, props);

    $(document.body).toggleClass('action-overlay-active', isUserChoosingMoveDestination);
  }

  if (editMode) {
    // Non-flex components use componentResizable
    // Flex components use ComponentDraggerControl below
    const needsComponentDragger = isFlexibleStory() && componentData.type !== COMPONENT_TYPE_GLOBAL_FILTER;
    const editControlsSelector = needsComponentDragger
      ? 'component-edit-controls-container with-dragger'
      : 'component-edit-controls-container';

    // for flexible stories, add ComponentDraggerControl bar before ComponentEditMenu
    if (needsComponentDragger) {
      let draggerContainer = this.find('.component-dragger-container');
      if (draggerContainer.length === 0) {
        draggerContainer = $('<div>', { class: 'component-dragger-container component-base' }).appendTo(this);
      }

      const onRemove = (): void => {
        // Create a custom window event to be picked up in EditBlockSection.
        // To remove a flexible component, must also update React Grid Layout container and elementCache
        // A REMOVE_COMPONENT dispatch here created a race condition
        const removeComponentEvent = new window.CustomEvent<RemoveComponentDetails>('REMOVE_COMPONENT', {
          detail: {
            blockId,
            componentIndex
          }
        });
        const $element = $(this);
        const blockSectionElement = $element[0].closest('.block-grid-container');
        blockSectionElement.dispatchEvent(removeComponentEvent);
      };

      const hover = (): void => {
        this.toggleClass('remove-border');
      };

      // ComponentDraggerControl UI rendered here because it's tightly coupled with ComponentEditMenu css
      ReactDOM.render(<ComponentDraggerControl remove={onRemove} hover={hover} />, draggerContainer[0]);
    }

    let container = this.find('.component-edit-controls-container.component-base');
    if (container.length === 0) {
      container = $('<div>', { class: `${editControlsSelector} component-base` }).appendTo(this);
    }

    const editMenuProps = {
      blockId: blockId as string,
      componentIndex: componentIndex as number,
      componentData,
      editButtonSupported: editButtonSupported as boolean,
      updateTableButtonSupported
    };

    ReactDOM.render(<ComponentEditMenu {...editMenuProps} />, container[0]);
  }

  // TODO: Remove resizeSupported prop from componentBase and component renderers
  // once feature flag enable_flexible_story_layout is removed
  if (editMode && resizeSupported && !isFlexibleStory()) {
    this.componentResizable(resizeOptions);
  }

  if (isFilterableAsset) {
    renderFilterIcon(this, props, dataSources as string[]);
  }

  if (!this.data('component-rendered')) {
    // First render
    this.data('component-rendered', true);
    // @ts-ignore
    firstRenderCallback.call(this, componentData);
  }

  if (!_.isEqual(currentData, componentData)) {
    this.data('component-rendered-data', componentData);
    // @ts-ignore
    dataChangedCallback.call(this, componentData);
  }

  return this;
}

function renderFilterIcon(element: JQuery, props: ComponentProps, dataSources: string[]): void {
  let container = element.find('.component-filter-icon-container');

  if (element.find('.component-filter-icon-container').length === 0) {
    container = $('<div>', {
      class: `component-filter-icon-container ${isTylerForgeVizLayoutEnabled && 'forge-viz-layout'}`
    }).appendTo(element);
  }

  // @ts-ignore-error This is a valid EventListener, but there's no way to provide the mapping correctly to Typescript.
  const updatedFilters: EventListener = (event: GlobalFiltersUpdatedEvent) => {
    const newFilters = event.detail.filters;
    renderFilterIcon(element, { ...props, additionalFilters: newFilters }, dataSources);
  };
  element[0].removeEventListener('GLOBAL_FILTERS_UPDATED', updatedFilters);
  element[0].addEventListener('GLOBAL_FILTERS_UPDATED', updatedFilters);
}

function renderOverlayContainer(element: JQuery, action: string): JQuery {
  let $overlayContainer = element.find(`.component-edit-${action}-overlay-container`);

  if ($overlayContainer.length === 0) {
    $overlayContainer = $('<div>', { class: `component-edit-${action}-overlay-container` });
    element.append($overlayContainer);
  }

  return $overlayContainer;
}

function renderMoveActionOverlay(element: JQuery, props: ComponentProps): void {
  const { isUserChoosingMoveDestination, isComponentBeingMoved, isComponentValidMoveDestination } = props;
  const $overlayContainer = renderOverlayContainer(element, COMPONENT_ACTION_TYPES.MOVE);

  const onActionClick = (): void => {
    const { blockId, componentIndex } = element.data('props');
    const sourceComponent = { blockId: selectors.getSourceBlockId(StorytellerReduxStore.getState()), componentIndex: selectors.getSourceComponentIndex(StorytellerReduxStore.getState()) };

    dispatcher.dispatch({
      action: Actions.MOVE_COMPONENT_DESTINATION_CHOSEN,
      blockId,
      componentIndex: parseInt(componentIndex, 10),
      sourceComponent
    });
  };

  const onCancelAction = (): void => {
    dispatcher.dispatch({
      action: Actions.ACTION_COMPONENT_CANCEL
    });
  };

  const actionOverlayProps = {
    actionButtonText: I18n.t('editor.components.edit_controls.swap_here'),
    isActive: isUserChoosingMoveDestination as boolean,
    onActionClick,
    onCancelAction,
    shouldShowActionButton: isComponentValidMoveDestination as boolean,
    shouldShowCancelButton: isComponentBeingMoved as boolean
  };

  ReactDOM.render(<ComponentActionOverlay {...actionOverlayProps} />, $overlayContainer[0]);
}
