import _ from 'lodash';
import Actions from 'Actions';
import {
  assertHasProperties,
  assertHasProperty,
  assertInstanceOf,
  assertIsOneOfTypes,
  assertIsString
} from 'common/assertions';
import I18n from 'common/i18n';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';

import { actionComponentStore } from 'editor/stores/ActionComponentStore';
import httpRequest, { storytellerHeaders } from 'services/httpRequest.js';
import Constants from 'lib/Constants';
import {
  Block,
  BlockComponent,
  BlockComponentPayload,
  BlockDict,
  ComponentType,
  COMPONENT_TYPE_AUTHOR,
  COMPONENT_TYPE_HERO,
  COMPONENT_TYPE_HTML,
  FluxPayload,
  Story,
  StoryData
} from 'types';
import { addAnchorToHeadings, generateTableOfContents, tocComponentIndex } from 'lib/TableOfContentsUtils.js';
import { isLayoutEqual } from 'lib/FlexibleLayoutUtils';
import Environment from 'StorytellerEnvironment';
import isComponentUnsupported from 'lib/UnsupportedComponentTypes';
import { createAction, createReducer, createAsyncThunk } from '@reduxjs/toolkit';
import {
  validateStoryData,
  getBlockAndComponent
} from 'store/selectors/StorySelectors/Stories';
import { Layout } from 'react-grid-layout';

import { getBlockComponentAtIndex } from 'store/selectors/StorySelectors/Blocks';
import {
  createStory,
  prepareStoryForTemplate,
  replaceStoryFromJsonImport,
  replaceStoryFromTemplate
} from 'store/TopLevelActions';

//=============================================================================
// State & Types
//=============================================================================
interface StoryStoreState {
  stories: StoryDict;
  blocks: BlockDict;
}

interface StoryDict {
  [storyUid: string]: Story;
}

interface AsyncPastComponentPayload {
  blockId: string;
  componentIndex: number;
  layout: Layout;
  copiedContent: {
    type: ComponentType;
    value: string;
  };
}

const initialState: StoryStoreState = {
  stories: {} as StoryDict,
  blocks: {} as BlockDict
};

//=============================================================================
// Action Creators
//=============================================================================
export const setPublishedStory = createAction<FluxPayload>(Actions.STORY_SET_PUBLISHED_STORY);
export const updateStory = createAction<FluxPayload>(Actions.STORY_UPDATED);
export const setStoryTitle = createAction<FluxPayload>(Actions.STORY_SET_TITLE);
export const setStoryDescription = createAction<FluxPayload>(Actions.STORY_SET_DESCRIPTION);
export const setStoryTileConfig = createAction<FluxPayload>(Actions.STORY_SET_TILE_CONFIG);
export const setStoryPermissions = createAction<FluxPayload>(Actions.STORY_SET_PERMISSIONS);
export const moveStoryBlockUp = createAction<FluxPayload>(Actions.STORY_MOVE_BLOCK_UP);
export const moveStoryBlockDown = createAction<FluxPayload>(Actions.STORY_MOVE_BLOCK_DOWN);
export const toggleStoryBlockPresentationVisibility = createAction<FluxPayload>(
  Actions.STORY_TOGGLE_BLOCK_PRESENTATION_VISIBILITY
);
export const deleteStoryBlock = createAction<FluxPayload>(Actions.STORY_DELETE_BLOCK);
export const insertStoryBlock = createAction<FluxPayload>(Actions.STORY_INSERT_BLOCK);
export const insertStoryTableOfContents = createAction<FluxPayload>(Actions.STORY_INSERT_TABLE_OF_CONTENTS);
export const insertBlockComponent = createAction<FluxPayload>(Actions.BLOCK_INSERT_COMPONENT);
export const deleteBlockComponent = createAction<FluxPayload>(Actions.BLOCK_DELETE_COMPONENT);
export const updateBlockComponent = createAction<FluxPayload>(Actions.BLOCK_UPDATE_COMPONENT);
export const updateBlockComponentLayout = createAction<FluxPayload>(Actions.BLOCK_UPDATE_COMPONENTS_LAYOUT);
export const updateBlockColor = createAction<FluxPayload>(Actions.BLOCK_UPDATE_COLOR);
export const updateStoryTheme = createAction<FluxPayload>(Actions.STORY_UPDATE_THEME);
export const updateStoryLayout = createAction<FluxPayload>(Actions.STORY_UPDATE_LAYOUT);
export const resetComponent = createAction<FluxPayload>(Actions.RESET_COMPONENT);
export const copyComponent = createAction<FluxPayload>(Actions.COPY_COMPONENT);
export const chosenMoveComponentDestination = createAction<FluxPayload>(
  Actions.MOVE_COMPONENT_DESTINATION_CHOSEN
);
export const resetStoryStore = createAction(Actions.STORY_STORE_RESET);

//=============================================================================
// Redux Thunks
//=============================================================================

export const pasteComponent = createAsyncThunk(
  Actions.PASTE_COMPONENT,
  async (payload: AsyncPastComponentPayload) => {
    let copiedContent;
    try {
      const rawJSON = await navigator.clipboard.readText();
      copiedContent = JSON.parse(rawJSON);

      if (Constants.COPYABLE_DOCUMENT_COMPONENT_TYPES.includes(copiedContent.type)) {
        const url =
          `${Constants.API_PREFIX_PATH}/stories/${Environment.STORY_UID}` +
          `/documents/${copiedContent.value.documentId}/duplicate`;

        const options = { headers: storytellerHeaders() };

        const newDocument = await httpRequest('POST', url, options);

        copiedContent.value.documentId = newDocument.data.document.id;
        copiedContent.value.url = newDocument.data.document.url;
        copiedContent.value.originalUrl = newDocument.data.document.original_url;
      }
    } catch {
      showToastNow({
        type: ToastType.ERROR,
        content: I18n.t('editor.components.edit_controls.paste_garbage')
      });
      return;
    }
    return {
      ...payload,
      copiedContent
    } as AsyncPastComponentPayload;
  }
);

//=============================================================================
// Selectors
//=============================================================================

export const selectors = {};

//=============================================================================
// Story Reducer
//=============================================================================
const storyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createStory, (state, action) => {
      const { payload } = action;

      // Make sure payload from Redux and Flux both work
      const storyData = payload.data || payload;

      // TODO: investigate why this assert was expected or necessary.
      // Removed when implementing the new redux store for the global filter.
      // assert(
      //   !state.stories.hasOwnProperty(storyData.uid),
      //   `Cannot import story: story with uid ${storyData.uid} already exists.`
      // );

      setStory(storyData, state.stories, state.blocks);
    })
    .addCase(setPublishedStory, (state, action) => {
      const { payload } = action;
      assertIsOneOfTypes(payload, 'object');
      assertHasProperties(payload, 'storyUid', 'publishedStory');

      const { storyUid, publishedStory } = payload;
      assertIsOneOfTypes(payload.storyUid, 'string');

      state.stories[storyUid].publishedStory = publishedStory;
    })
    .addCase(deleteStoryBlock, (state, action) => {
      const { payload } = action;
      const { storyUid, blockId } = payload;
      deleteBlock({ storyUid, blockId }, state);
    })
    .addCase(updateStory, (state, action) => {
      const { payload } = action;
      assertHasProperties(payload, 'storyUid', 'updatedAt');

      const { storyUid, updatedAt } = payload;
      const story = state.stories[storyUid];

      story.updatedAt = updatedAt;
    })
    .addCase(moveStoryBlockUp, (state, action) => {
      const { payload } = action;
      assertHasProperty(payload, 'storyUid');
      assertHasProperty(payload, 'blockId');

      const { storyUid, blockId } = payload;
      assertIsString(storyUid);
      assertIsString(blockId);

      // We'll handle the null case with an assertion
      const blockIndex = getStoryBlockIndexWithId(storyUid, blockId, state) as number;

      swapStoryBlocksAtIndices(storyUid, blockIndex, blockIndex - 1, state);
    })
    .addCase(replaceStoryFromJsonImport, (state, action) => {
      const { payload } = action;
      let storyJson = payload.story;
      // Patch up story json to work in this story.
      storyJson = {
        // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
        ...Environment.STORY_DATA!,
        blocks: storyJson.blocks,
        theme: storyJson.theme
      };

      if (storyJson.theme && storyJson.theme.indexOf('custom') >= 0) {
        storyJson.theme = 'forge';
      }

      // Blank out components which are dangerous to copy like this.
      let didRemoveDangerous = false;
      storyJson.blocks.forEach((block: Block) => {
        // @ts-ignore
        block.components = block.components.map((component) => {
          const dangerous = _.includes(
            ['socrata.visualization.classic', 'image', 'embeddedHtml'],
            component.type
          );
          didRemoveDangerous = didRemoveDangerous || dangerous;
          return dangerous ? { type: 'assetSelector' } : component;
        });
      });
      setStory(storyJson, state.stories, state.blocks);
      if (didRemoveDangerous) {
        alert(
          // eslint-disable-line no-alert
          'Blanked out some dangerous blocks. Images and classic visualizations cannot be cloned in this way.'
        );
      }
    })
    .addCase(prepareStoryForTemplate, (state, action) => {
      const { payload } = action;
      assertHasProperties(payload, 'templateContent', 'storyUid', 'layout');

      const { storyUid, templateContent, layout } = payload;
      assertIsOneOfTypes(templateContent, 'object');
      assertIsOneOfTypes(storyUid, 'string');
      assertIsOneOfTypes(layout, 'string');

      const story = state.stories[storyUid];
      story.layout = layout;
    })
    .addCase(replaceStoryFromTemplate, (state, action) => {
      const { payload } = action;
      assertHasProperties(payload, 'templateContent', 'storyUid', 'layout');

      const { storyUid, templateContent, layout } = payload;
      assertIsOneOfTypes(templateContent, 'object');
      assertIsOneOfTypes(storyUid, 'string');
      assertIsOneOfTypes(layout, 'string');
      // The Story we get will be essentially transformed into a StoryData when we set `blocks` below,
      const story = state.stories[storyUid];

      story.blocks = templateContent;
      setStory(story, state.stories, state.blocks);
    })
    .addCase(setStoryTitle, (state, action) => {
      const { payload } = action;
      assertHasProperty(payload, 'storyUid');
      assertHasProperty(payload, 'title');

      const { storyUid, title } = payload;
      assertIsString(storyUid);
      assertIsString(title);

      state.stories[storyUid].title = title;
    })
    .addCase(setStoryDescription, (state, action) => {
      const { payload } = action;
      const { storyUid, description } = payload;
      state.stories[storyUid].description = description;
    })
    .addCase(setStoryTileConfig, (state, action) => {
      const { payload } = action;
      assertIsOneOfTypes(payload, 'object');

      const { storyUid, tileConfig } = payload;
      assertIsOneOfTypes(storyUid, 'string');
      assertIsOneOfTypes(tileConfig, 'object');
      assertIsOneOfTypes(tileConfig.title, 'string');
      assertIsOneOfTypes(tileConfig.description, 'string');

      // EN-49336: We no longer want to allow the user to save a blank embed description
      // If they remove the embed description, it should default to the story description.
      if (_.isEmpty(tileConfig.description)) {
        delete tileConfig.description;
      }

      // EN-55551: We no longer want to allow the user to save a blank embed title
      // If they remove the embed title, it should default to the story title.
      if (_.isEmpty(tileConfig.title)) {
        delete tileConfig.title;
      }

      state.stories[storyUid].tileConfig = tileConfig;
    })
    .addCase(setStoryPermissions, (state, action) => {
      const { payload } = action;
      assertIsOneOfTypes(payload, 'object');
      assertHasProperties(payload, 'storyUid', 'isPublic');

      const { storyUid, isPublic } = action.payload;
      assertIsOneOfTypes(storyUid, 'string');
      assertIsOneOfTypes(isPublic, 'boolean');

      state.stories[storyUid].permissions = { isPublic };
    })
    .addCase(moveStoryBlockDown, (state, action) => {
      const { payload } = action;
      assertHasProperty(payload, 'storyUid');
      assertHasProperty(payload, 'blockId');

      const { storyUid, blockId } = payload;
      assertIsString(storyUid);
      assertIsString(blockId);

      // We'll handle the null case with an assertion
      const blockIndex = getStoryBlockIndexWithId(storyUid, blockId, state) as number;
      swapStoryBlocksAtIndices(storyUid, blockIndex, blockIndex + 1, state);
    })
    .addCase(toggleStoryBlockPresentationVisibility, (state, action) => {
      const { payload } = action;
      assertHasProperty(payload, 'blockId');
      const { blockId } = payload;
      assertIsString(blockId);

      const block = state.blocks[blockId];
      block.presentable = !block.presentable;
    })
    .addCase(insertStoryBlock, (state, action) => {
      const { payload } = action;
      assertHasProperty(payload, 'storyUid');
      assertHasProperty(payload, 'insertAt');
      const storyUid = payload.storyUid as string;
      const insertAt = payload.insertAt as number;
      const blockContent = payload.blockContent as Block;
      assertIsOneOfTypes(storyUid, 'string');
      assertIsOneOfTypes(insertAt, 'number');

      if (typeof payload.insertAt !== 'number') {
        throw new Error(`\`insertAt\` must be a number (is of type ${typeof insertAt}.`);
      }

      const { blocks } = state;
      const clonedBlock = cloneBlock(blockContent);
      const blockId = importBlockAndGenerateClientSideId(clonedBlock, blocks);

      insertStoryBlockAtIndex(storyUid, blockId, insertAt, state);
      if (tocComponentIndex(blockContent) !== -1) {
        insertTableOfContents({ storyUid, blockWithToCId: blockId }, state);
      }
    })
    .addCase(insertStoryTableOfContents, (state, action) => {
      const { payload } = action;
      const { storyUid, blockWithToCId } = payload;
      insertTableOfContents({ storyUid, blockWithToCId }, state);
    })
    .addCase(insertBlockComponent, (state, action) => {
      const { payload } = action;
      assertHasProperties(payload, 'type', 'value', 'blockId', 'layout');

      const { blockId, type, value, layout } = payload;
      assertIsOneOfTypes(type, 'string');
      assertIsOneOfTypes(blockId, 'string');
      assertIsOneOfTypes(layout, 'object');

      const { blocks } = state;
      const block = blocks[blockId];
      const components = block.components;
      components.push({ type, value, layout });
    })
    .addCase(deleteBlockComponent, (state, action) => {
      const { payload } = action;
      assertHasProperties(payload, 'componentIndex', 'blockId', 'storyUid');

      const { blockId, storyUid, componentIndex } = action.payload;
      assertIsOneOfTypes(componentIndex, 'number', 'string');
      assertIsOneOfTypes(storyUid, 'string');
      assertIsOneOfTypes(blockId, 'string');

      const { blocks } = state;
      const block = blocks[blockId];
      const components = block.components;

      if (components.length === 1) {
        deleteBlock({ blockId, storyUid }, state);
      } else {
        components.splice(componentIndex, 1);
      }
    })
    .addCase(updateBlockComponent, (state, action) => {
      const { type, value, componentIndex, blockId } = action.payload;

      if (componentIndex === undefined) return; // This is a workaround

      let { layout } = action.payload;
      const { block, component } = getBlockAndComponent(blockId, componentIndex, state.blocks);

      // Checks if there is an existing layout on component,
      // If true, set undefined payload layout to component layout
      const componentLayout = _.get(component, 'layout');
      if (!layout) {
        layout = componentLayout;
      }

      if (
        !_.isEqual(component.type, type) ||
        !_.isEqual(component.value, value) ||
        !isLayoutEqual(component.layout, layout)
      ) {
        block.components[componentIndex] = {
          type: type,
          value: value,
          layout: layout ? layout : undefined
        };
      }
    })
    .addCase(updateBlockComponentLayout, (state, action) => {
      const { payload } = action;
      assertHasProperties(payload, 'components', 'blockId');
      const { blocks } = state;
      const { blockId, components } = action.payload;
      assertIsOneOfTypes(blockId, 'string');
      assertIsOneOfTypes(components, 'object');

      const block = blocks[blockId];
      const originalComponents = block.components;
      const payloadComponents = components;

      for (let i = 0; i < originalComponents.length; i++) {
        const originalLayout = originalComponents[i].layout;
        const payloadLayout = payloadComponents[i].layout;

        if (!isLayoutEqual(originalLayout, payloadLayout)) {
          originalComponents[i].layout = payloadLayout;
        }
      }
    })
    .addCase(updateBlockColor, (state, action) => {
      const { payload } = action;
      assertHasProperty(payload, 'blockId');
      assertHasProperty(payload, 'color');
      const { blockId, color } = payload;
      assertIsString(blockId);
      assertIsString(color);

      const { blocks } = state;
      const block = blocks[blockId];
      block.background_color = color;
    })
    .addCase(updateStoryTheme, (state, action) => {
      const { payload } = action;
      assertHasProperty(payload, 'storyUid');
      assertHasProperty(payload, 'theme');

      const { storyUid, theme } = payload;
      assertIsString(storyUid);
      assertIsString(theme);

      const { stories } = state;
      stories[storyUid].theme = theme;
    })
    .addCase(updateStoryLayout, (state, action) => {
      const { storyUid, layout } = action.payload;
      const { stories } = state;

      stories[storyUid].layout = layout;
    })
    .addCase(resetComponent, (state, action) => {
      const { payload } = action;
      updateBlockComponentAtIndex(payload as BlockComponentPayload, state);
    })
    .addCase(copyComponent, (state, action) => {
      const { payload } = action;
      assertHasProperties(payload, 'componentIndex', 'blockId');

      const { componentIndex, blockId } = payload;
      const { blocks } = state;
      const { component } = getBlockAndComponent(blockId, componentIndex, blocks);

      navigator.clipboard.writeText(JSON.stringify(component)).then(() => {
        showToastNow({
          type: ToastType.SUCCESS,
          content: I18n.t('editor.components.edit_controls.copy_success')
        });
      });
    })
    .addCase(pasteComponent.fulfilled, (state, action) => {
      const { payload } = action;
      assertHasProperties(payload, 'componentIndex', 'blockId', 'copiedContent');

      if (payload === undefined) return;

      const { blockId, componentIndex, copiedContent } = payload;
      const { block, component } = getBlockAndComponent(blockId, componentIndex, state.blocks);

      if (
        !_.isEqual(component.type, copiedContent.type) ||
        !_.isEqual(component.value, copiedContent.value)
      ) {
        block.components[componentIndex] = {
          type: copiedContent.type,
          value: copiedContent.value,
          // Would be undefined in classic layouts.
          layout: component.layout
        };
      }
    })
    .addCase(chosenMoveComponentDestination, (state, action) => {
      const { payload } = action;
      assertHasProperties(payload, 'blockId', 'componentIndex', 'sourceComponent');

      const { blockId, componentIndex, sourceComponent} = payload;
      const { blocks } = state;
      const destinationBlock = blocks[blockId];
      const destinationIndex = parseInt(componentIndex, 10);
      const sourceComponentData = getBlockComponentAtIndex(
        sourceComponent.blockId,
        sourceComponent.componentIndex,
        blocks
      );
      const sourceIndex = parseInt(sourceComponent.componentIndex, 10);
      const sourceBlock = blocks[sourceComponent.blockId];

      sourceBlock.components[sourceIndex] = getBlockComponentAtIndex(blockId, componentIndex, blocks);
      destinationBlock.components[destinationIndex] = sourceComponentData;

      // Checks if there is an existing layout on component
      // If true, swap the component layouts back to the original components
      const sourceComponentLayout = _.get(sourceComponentData, 'layout');
      if (sourceComponentLayout) {
        const tempLayout = sourceBlock.components[sourceIndex].layout;
        sourceBlock.components[sourceIndex].layout = sourceComponentLayout;
        destinationBlock.components[destinationIndex].layout = tempLayout;
      }
    })
    .addCase(resetStoryStore, (state) => {
      state.stories = initialState.stories;
      state.blocks = initialState.blocks;
    });
});

//=============================================================================
// Private Methods
//=============================================================================

const setStory = (storyData: StoryData, stories: StoryDict, blocks: BlockDict) => {
  validateStoryData(storyData);

  const storyUid = storyData.uid;

  const UnsupportedComponentTypesRemoved = _.map(storyData.blocks, (block: Block) => {
    return dropUnsupportedComponents(block);
  });

  const blockIds = [] as string[];
  _.map(UnsupportedComponentTypesRemoved, (block: Block) => {
    blockIds.push(importBlockAndGenerateClientSideId(block, blocks));
  });

  const newStory: Story = {
    uid: storyUid,
    title: storyData.title,
    description: storyData.description,
    tileConfig: storyData.tileConfig,
    dataSource: storyData.dataSource,
    theme: storyData.theme,
    layout: storyData.layout,
    blockIds: blockIds,
    permissions: storyData.permissions,
    // since they're not validated in _validateStory data, these properties are apparently
    // assumed to be set, so encode that assumption in the type
    createdBy: storyData.createdBy as string,
    updatedAt: storyData.updatedAt as string
  };
  stories[storyUid] = newStory;
};

const dropUnsupportedComponents = (block: Block) => {
  return {
    ...block,
    components: _.map(block.components, (component) => {
      if (isComponentUnsupported(component)) {
        return { type: 'assetSelector' };
      } else {
        return component;
      }
    })
  };
};

const importBlockAndGenerateClientSideId = (blockData: Block, blocks: BlockDict): string => {
  const clientSideBlockId = generateClientSideId();
  setBlock(clientSideBlockId, blockData, blocks);
  return clientSideBlockId;
};

const generateClientSideId = () => {
  return _.uniqueId('clientSideId_');
};

const setBlock = (clientSideBlockId: string, blockData: Block, blocks: BlockDict) => {
  validateBlockData(blockData);

  if (blocks.hasOwnProperty(clientSideBlockId)) {
    throw new Error(`Block with id ${clientSideBlockId} already exists.`);
  }

  blocks[clientSideBlockId] = {
    layout: blockData.layout,
    components: cloneBlockComponents(blockData.components),
    presentable: blockData.presentable,
    background_color: blockData.background_color
  };
};

const validateBlockData = (blockData: Block) => {
  assertIsOneOfTypes(blockData, 'object');
  assertHasProperty(blockData, 'layout');
  assertHasProperty(blockData, 'components');
  assertInstanceOf(blockData.components, Array);

  if ('id' in blockData) {
    throw new Error('Unexpected block ID in block JSON');
  }

  blockData.components.forEach(function (component) {
    assertHasProperty(component, 'type');
  });
};

const cloneBlockComponents = (components: BlockComponent[]) => {
  return components.map(function (component) {
    return _.pick(component, ['id', 'type', 'value', 'layout']) as BlockComponent;
  });
};

const getStoryBlockIndexWithId = (storyUid: string, blockId: string, state: StoryStoreState) => {
  const story = state.stories[storyUid];
  let index: number | null = story.blockIds.indexOf(blockId);

  if (index === -1) {
    index = null;
  }

  return index;
};

const swapStoryBlocksAtIndices = (
  storyUid: string,
  index1: number,
  index2: number,
  state: StoryStoreState
) => {
  assertIsOneOfTypes(index1, 'number');
  assertIsOneOfTypes(index2, 'number');

  const story = state.stories[storyUid];
  const storyBlockIdCount = story.blockIds.length;

  if (index1 < 0 || index1 >= storyBlockIdCount) {
    throw new Error(
      `\`index1\` argument is out of bounds; index1: "${index1}", ` +
        `storyBlockIdCount: "${storyBlockIdCount}".`
    );
  }

  if (index2 < 0 || index2 >= storyBlockIdCount) {
    throw new Error(
      `\`index2\` argument is out of bounds; index1: "${index2}", ` +
        `storyBlockIdCount: "${storyBlockIdCount}".`
    );
  }

  const tempBlock = story.blockIds[index1];
  story.blockIds[index1] = story.blockIds[index2];
  story.blockIds[index2] = tempBlock;
};

const insertStoryBlockAtIndex = (
  storyUid: string,
  blockId: string,
  index: number,
  state: StoryStoreState
) => {
  const story = state.stories[storyUid];
  const storyBlockIdCount = story.blockIds.length;

  assertIsOneOfTypes(blockId, 'string');
  assertIsOneOfTypes(index, 'number');

  if (index < 0 || index > storyBlockIdCount) {
    throw new Error(
      `\`index\` argument is out of bounds; index: "${index}", storyBlockIdCount: "${storyBlockIdCount}".`
    );
  }

  if (index === storyBlockIdCount) {
    story.blockIds.push(blockId);
  } else {
    story.blockIds.splice(index, 0, blockId);
  }
};

const cloneBlock = (blockContent: Block): Block => {
  const block = blockContent;

  return {
    layout: block.layout,
    components: cloneBlockComponents(block.components),
    presentable: block.presentable
  };
};

const deleteBlock = (payload: { storyUid: string; blockId: string }, state: StoryStoreState) => {
  const { storyUid, blockId } = payload;
  const { stories } = state;
  const story = stories[storyUid];
  const indexOfBlockIdToRemove = story.blockIds.indexOf(blockId);

  if (indexOfBlockIdToRemove < 0) {
    const payloadJson = JSON.stringify(payload);
    const blockIdsJson = JSON.stringify(story.blockIds);
    const blocksJson = JSON.stringify(state.blocks);
    throw new Error(
      `\`blockId\` does not exist in story; payload: "${payloadJson}", ` +
        `blockIds: "${blockIdsJson}", serializedStory: "${blocksJson}"`
    );
  }

  story.blockIds.splice(indexOfBlockIdToRemove, 1);
};

const insertTableOfContents = (
  payload: { blockWithToCId: string; storyUid: string },
  state: StoryStoreState
) => {
  assertHasProperties(payload, 'storyUid', 'blockWithToCId');

  const { blockWithToCId, storyUid } = payload;
  const { stories, blocks } = state;
  const { blockIds } = stories[storyUid];
  // Update each HTML-like block's anchor tags. This ensures the links we generate in the ToC
  // match up with the anchor tags in the content linked to.
  blockIds.forEach((blockId: string) => {
    // NOTE: RichTextEditor instances don't react deterministically to multiple content updates
    // within a single UI frame/action. So it's important not to trigger a call to _emitChange
    // before we've updated everything.
    // Choosing not to fix this timing issue right now because a *lot* of testing has been invested
    // in the current RichTextEditor -> Flux event model. This area of code has historically been
    // very sensitive to small perturbations, and this is the first instance we've been bitten
    // by this timing sensitivity.
    const { components } = blocks[blockId];
    for (let index = 0; index < components.length; index++) {
      const component = components[index];
      const { type } = component;
      // Important: Objects in this store are supposed to be immutable. Modify them at your
      // peril. Prefer creating a new object vs. mutation.
      let htmlPath: string | null = null;
      switch (type) {
        case COMPONENT_TYPE_HTML: {
          htmlPath = 'value';
          break;
        }
        case COMPONENT_TYPE_AUTHOR: {
          htmlPath = 'value.blurb';
          break;
        }
        case COMPONENT_TYPE_HERO: {
          htmlPath = 'value.html';
          break;
        }
        default:
          break;
      }
      if (htmlPath) {
        const anchoredHtml = addAnchorToHeadings($(`<div>${_.get(component, htmlPath)}</div>`)[0]);
        components[index] = _.set(_.cloneDeep(component), htmlPath, anchoredHtml.innerHTML);
      }
    }
  });

  const tocBlock = blocks[blockWithToCId];
  const tocIndex = tocComponentIndex(tocBlock);
  tocBlock.components[tocIndex] = {
    ...tocBlock.components[tocIndex],
    value: generateTableOfContents()
  };
};

const updateBlockComponentAtIndex = (payload: BlockComponentPayload, state: StoryStoreState) => {
  assertHasProperties(payload, 'componentIndex', 'type', 'value', 'blockId');
  assertIsOneOfTypes(payload.type, 'string');

  const { blocks } = state;
  const { blockId, componentIndex, layout, value, type } = payload;
  const { block, component } = getBlockAndComponent(blockId, componentIndex, blocks);

  // Checks if there is an existing layout on component
  // If true, set undefined payload layout to component layout
  const payloadComponentData = _.get(payload, 'layout');
  const componentLayout = _.get(component, 'layout');
  if (!payloadComponentData) {
    _.set(payload, 'layout', componentLayout);
  }

  if (
    !_.isEqual(component.type, type) ||
    !_.isEqual(component.value, value) ||
    !isLayoutEqual(component.layout, layout)
  ) {
    block.components[payload.componentIndex] = {
      type: payload.type,
      value: payload.value,
      // Would be undefined in classic layouts.
      layout: payload.layout
    };
  }
};

export default storyReducer;
