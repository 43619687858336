import $ from 'jquery';
import _ from 'lodash';
import Squire from 'squire';

import { assert, assertInstanceOf, assertIsOneOfTypes } from 'common/assertions';

import StorytellerUtils from 'lib/StorytellerUtils';
import Constants from 'lib/Constants';
import CustomEvent from 'CustomEvent.js';
import { exceptionNotifier } from 'services/ExceptionNotifier';

import Sanitizer from './Sanitizer.js';
import RichTextEditorFormatController from './RichTextEditorFormatController.js';
import { windowSizeBreakpointStore } from './stores/WindowSizeBreakpointStore';

/**
 * Redux store imports
 */
import { StorytellerReduxStore } from '../store/StorytellerReduxStore';
import { openLinkTip, closeLinkTip } from '../store/reducers/LinkTipSlice';

const MIN_LAYOUT_HEIGHT = 2;
// TODO EN-50252 remove these stub types when the editor and toolbar are converted to TS
interface RichTextEditorFormatController {
  getActiveFormats: () => any;
}

/**
 * Takes an object with the same contents as a DOMRect and converts it into a DOMRect object
 * We should not store non-serializable items in our store:
 * https://redux.js.org/faq/actions#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants
 * @returns DOMRect
 */
const getBoundingClientRectObject = (element: HTMLElement) => {
  const { top, right, bottom, left, width, height, x, y } = element.getBoundingClientRect();
  return { top, right, bottom, left, width, height, x, y };
};

// Compare two pieces of HTML editor contents, treating HTML entities
// as equivalent to the character that is their actual meaning. I.e.,
// const a = '<foo\xa0bar>', b = '&lt;foo&nbsp;bar&gt;';
// htmlEntityAwareStringEquals(a, b); // true
//
// This is useful because Squire will sometimes give us HTML with proper escaped
// entities and sometimes HTML containing characters that should be escaped. The
// latter seems to happen on corner cases like pasting from MS Word.
const htmlEntityAwareStringEquals = (a: string, b: string) => {
  function parseHtmlEntities(html: string) {
    return _.unescape(html).replace(/&nbsp;/g, '\xa0'); // Lodash's unescape() does not unescape &nbsp;
  }

  return parseHtmlEntities(a) === parseHtmlEntities(b);
};

/**
 * @constructor
 * @param {jQuery} element
 * @param {string} editorId
 * @param {string} [contentToPreload] - The content that should be inserted
 *   into the newly-created editor.
 */
export default class RichTextEditor {
  private editorId: string;
  private _lastSetContent?: string;

  // _editor is the Squire instance.
  private _editor: any | null;
  private _contentToPreload?: string;
  private _formatController: RichTextEditorFormatController | null;

  // Last content passed to setContent(), but transformed through
  // Squire/the browser's normalization rules.
  // See comment in _broadcastContentChangeWhenSettled.
  private _lastSetContentSquireNormalized: string;
  private _lastContentHeight: number;
  private _lastLayoutHeight: number;
  private _disableHeightChange: boolean;
  private _editorElement: JQuery<HTMLIFrameElement> | null;
  private _containerElement: JQuery<HTMLElement>;
  private _defaultThemesCss: string;
  private _customThemesCss: string;
  private _editorBodyElement: JQuery<HTMLBodyElement> | null;
  private _contentWindowDocument: Document;
  private _formats: typeof Constants['RICH_TEXT_FORMATS'];
  public id: string;

  constructor(
    element: JQuery<HTMLElement>,
    editorId: string,
    formats: typeof Constants['RICH_TEXT_FORMATS'],
    contentToPreload?: string
  ) {
    assertInstanceOf(element, $);
    assert(element.length !== 0, '`element` did not match any DOM nodes.');
    assert(element.length === 1, '`element` matches more than one DOM node.');
    assertIsOneOfTypes(editorId, 'number', 'string');
    assertIsOneOfTypes(contentToPreload, 'undefined', 'string');
    assertInstanceOf(formats, Array);

    this.editorId = editorId;
    this.id = editorId;
    // Last content passed to setContent(), verbatim.
    this._lastSetContent = undefined;
    this._lastContentHeight = 0;
    this._lastLayoutHeight = MIN_LAYOUT_HEIGHT;
    this._disableHeightChange = false;
    this._editorBodyElement = null;
    this._formatController = null;
    this._formats = formats;
    // _editorElement is the <iframe> associated with the Squire instance.
    this._editorElement = null;
    this._containerElement = element;
    this._defaultThemesCss = $('#themes').html() || '';
    this._customThemesCss = $('#custom').html() || '';
    _.bindAll(this, [
      '_broadcastFormatChange',
      '_broadcastFocus',
      '_broadcastBlur',
      'adjustHeight',
      'addContentClass',
      '_handleContentChange',
      '_linkActionTip',
      '_broadcastContentClick',
      '_broadcastFormatChangeOnArrowKeydown'
    ]);

    if (typeof contentToPreload !== 'undefined') {
      this._contentToPreload = contentToPreload;
      this._createEditor();
    }
  }

  /**
   * Public methods
   */

  public getFormatController(): RichTextEditorFormatController | null {
    return this._formatController;
  }

  public getContent(): string | void {
    if (this._editor) {
      return this._editor.getHTML();
    }
  }

  /**
   * Sets the content of the editor.
   * Does not treat the new content as a user-initiated
   * action. If this is not your intention, use a StorytellerReduxStore action
   * to set the content instead.
   *
   * The practical implication of this not being treated as a
   * user-initiated action is that any sanitization will not be
   * re-broadcast as a content change (so it doesn't end up in undo-redo).
   */
  public setContent(newContent: string): void {
    // Different browsers give slightly-different HTML for the same content. For example,
    // Chrome specifies inline colors like this:
    // <span style="color:rgb(x, y, z)">
    // while IE uses a hex representation:
    // <span style="color: #AABBCC">.
    //
    // This matters because setting the content steals focus (in IE only).
    // The net result of this is focus going haywire when a user edits a
    // story with some text having custom colors. As a workaround, we
    // remember what content an editor is "supposed" to have and skip
    // setting the content if we've already tried to set that content.
    // We can't use _editor.getHTML (or this.contentDiffersFrom) because
    // then we'd have to parse inline CSS and convert between color
    // representations (and who knows what other differences lurk).
    //
    // Also, there's a non-trivial performance cost to setting HTML
    // all the time - we call this method quite liberally and often
    // unnecessarily. Eliminating those extra calls is not practical;
    // the best place to handle the issue is right here.
    if (newContent === this._lastSetContent) {
      return;
    }
    this._lastSetContent = newContent;

    if (this._editor === null) {
      // Our iframe hasn't loaded yet.
      // Save the content so it is preloaded
      // once the iframe loads.
      this._contentToPreload = newContent;
      return;
    }

    if (
      this._editor &&
      this.contentDiffersFrom(newContent) &&
      !htmlEntityAwareStringEquals(newContent, this._lastSetContentSquireNormalized)
    ) {
      this._editor.setHTML(newContent);
      this._lastSetContentSquireNormalized = this._editor.getHTML();
      this._updateContentHeight();
    }
  }

  public contentDiffersFrom(otherContent: string): boolean {
    return !htmlEntityAwareStringEquals(this._editor.getHTML(), otherContent);
  }

  public getContentHeight(): number {
    return this._lastContentHeight;
  }

  private _calculateLayoutHeight(): number {
    // Converts the height of this iframe in pixels to the React Grid Layout height. 30px ~= 1 row.
    return Math.max(Math.round(this._lastContentHeight / 30), 1);
  }

  // Add a layout-* and a theme-* class to the content <html> of the iframe, removing
  // any stale such classes.
  public applyThemeAndLayoutClass(theme: string, layout: string): void {
    this._callWithContentDocumentIfPresent(
      _.bind(function (contentDocument: Document) {
        const htmlElement = contentDocument.documentElement;

        StorytellerUtils.applyThemeAndLayoutClass(htmlElement, theme, layout, this.adjustHeight);
      }, this)
    );
  }

  // Adds an extra class to the content body.
  public addContentClass(extraClass: string): void {
    this._callWithContentDocumentIfPresent(function (contentDocument: Document) {
      // @ts-ignore
      $(contentDocument.querySelector('body')).addClass(extraClass);
    });
  }

  /**
   * Deselects the rich text <iframe>.
   */
  public deselect(): void {
    this._callWithContentDocumentIfPresent(function (contentDocument: Document) {
      const selection = contentDocument.getSelection();
      if (selection && selection.rangeCount > 0) {
        selection.removeAllRanges();
      }
    });
  }

  /**
   * This method assumes that jQuery's .remove() function will correctly
   * remove any event listeners attached to _editorElement or any of its
   * children.
   */
  public destroy(): void {
    windowSizeBreakpointStore.removeChangeListener(this._applyWindowSizeClass);
    this._editorElement?.remove();
  }

  public getSquireInstance(): any {
    return this._editor;
  }

  /**
   * Private methods
   */

  private _createEditor(): void {
    this._containerElement.attr('data-editor-id', this.editorId);
    this._editorElement = $('<iframe>');

    $(this._editorElement).on('load', (e: JQuery.Event) => this.onEditorElementLoad(e));
    this._containerElement.append(this._editorElement);
  }

  private onEditorElementLoad(e: JQuery.Event): void {
    // @ts-ignore - Content window does exist on type HTMLIFrameElement however Event.target returns type HTMLElement
    this._contentWindowDocument = e.target?.contentWindow.document;

    this._addThemeStyles(this._contentWindowDocument);
    this._editor = new Squire(this._contentWindowDocument);
    this._editorBodyElement = $(this._contentWindowDocument).find('body');

    this._formatController = new RichTextEditorFormatController(this, this._formats);

    this._contentWindowDocument.addEventListener('click', this._broadcastContentClick);
    this._contentWindowDocument.addEventListener('click', this._broadcastFormatChange);

    this._editor.addEventListener('focus', this._broadcastFocus);
    this._editor.addEventListener('blur', this._broadcastBlur);

    // TODO: If nothing obvious is broken with the Rich Text Editor Toolbar
    // when you come across this message, please remove the following two
    // commented-out lines. They do not seem to be required for the correct
    // functioning of the RTE toolbar (including updating the state of the
    // buttons and the color of the text color icon), and so can probably
    // be safely removed.
    //
    // _editor.addEventListener('focus', _broadcastFormatChange);
    this._editor.addEventListener('select', this._broadcastFormatChange);
    // This is needed to get the active text color swatch to update when
    // moving the cursor around with the arrow keys.
    this._editor.addEventListener('keydown', this._broadcastFormatChangeOnArrowKeydown);
    this._editor.addEventListener('pathChange', this._broadcastFormatChange);

    this._editor.addEventListener('input', this._handleContentChange);
    this._editor.addEventListener(
      'willPaste',
      _.bind(function (pasteEvent: any) {
        this._sanitizeClipboardInput(pasteEvent);
        this._handleContentChange();
      }, this)
    );
    this._editor.addEventListener(
      'drop',
      _.bind(function () {
        // We get no opportunity to edit the dropped content, so sanitize everything.
        this._sanitizeCurrentContent();
        this._handleContentChange();
      }, this)
    );

    this._editor.setKeyHandler('ctrl-k', this._clickEditorLinkButton);
    this._editor.setKeyHandler('meta-k', this._clickEditorLinkButton);

    // remove the underline key shortcut since underlines are not kept in the published story
    // we can't simply set the handler to `null`, since that only stops Squire's functions;
    // *some browsers* (IE, Edge) have default behavior associated with the key shortcut, and
    // preventDefault stops that as well.
    this._editor.setKeyHandler('ctrl-u', (editorInstance: string, keyEvent: KeyboardEvent) => {
      keyEvent.preventDefault();
    });
    this._editor.setKeyHandler('meta-u', (editorInstance: string, keyEvent: KeyboardEvent) => {
      keyEvent.preventDefault();
    });

    // Pre-load existing content (e.g. if we are editing an
    // existing resource).
    if (this._contentToPreload !== null) {
      this._editor.setHTML(this._contentToPreload);
      this._broadcastFormatChange();
    }

    // Bind these listeners *after* the HTML has been set because Squire will
    // fire a pathChange event in reaction to that HTML setting, and the
    // selection range is initialized to (0,0)... which means that the tooltip
    // will in fact appear if the HTML content starts with a link.
    _.defer(
      _.bind(function () {
        this._editor.addEventListener('mouseup', this._linkActionTip);
        this._editor.addEventListener('pathChange', this._linkActionTip);
      }, this)
    );

    this._setupMouseMoveEventBroadcast();

    //  _addThemeStyles uses a setTimeout() to wait for a theme to render before stylizing the height
    // because of this the WindowSizeClass also needs to be asynced in order to affect change on that
    // stylized height correctly, this ensures it does not get run until after that happens
    windowSizeBreakpointStore.addChangeListener(() => this._applyWindowSizeClass());
    setImmediate(() => this._applyWindowSizeClass());

    this._editorElement?.on('rich-text-editor::disable-height-change', (event, disable) => {
      // EN-55778: A theme change will cause a nested dispatch.
      // The theme button will tell us to disable, perform the dispatch, then re-enable.
      // We adjust height when re-enabling.
      // TODO: The way text heights work is bad. I tried to remove this specific workaround
      // by skipping more calls to `onLayoutChange` in EBS. It did solve this nested dispatch,
      // but caused other bugs. If we want this gone, we have to fix the text height system.
      this._disableHeightChange = disable;
      if (!this._disableHeightChange) {
        this.adjustHeight();
      }
    });

    this._editorElement?.on('rich-text-editor::force-height-change', () => {
      // Adjust height whenever an EBI width is dragged.
      this.adjustHeight();
    });
  }

  /**
   * @function _clickEditorLinkButton
   * @description
   * This function prevents the default behavior based on the event.
   * It will click the editor toolbar link button and focus on the
   * URL input field when present. The function is called to handle the Ctrl/Cmd + k shortcut
   * that allows the user to add or edit a link to their current selection.
   *
   * @param {Object} editor - the Squire instance (req'd for Squire setKeyHandler function)
   * @param {Object} event - a ctrl-k or meta-k event object
   */
  private _clickEditorLinkButton(editor: any, event: any) {
    event.preventDefault();
    $('.rich-text-editor-toolbar-btn-link').trigger('click');
    $('input[type="url"]').trigger('focus');
  }

  /**
   * @function _linkActionTip
   * @description
   * This event-bound function reads the current event that is bound
   * and decides whether or not the cursor/selection in either has/is
   * a link. In the case that it is a link, the anchor tag is discovered
   * through the selection and an action to open the LinkTip is prepared.
   *
   * @param {Object} event - A mouseup, or pathChange event object.
   */
  private _linkActionTip() {
    const selection = this._editor.getSelection();

    if (this._editor.hasFormat('a')) {
      // There are two cases:
      // - We have a cursor in the link,
      // - or we have a selection of the link.
      // This is complicated, however, by the fact that formatting options
      // such as color will add extra spans inside the anchor tag.
      // So just let jQuery figure the damn situation out.
      const anchor = $(selection.startContainer).closest('a[href]')[0];

      if (!anchor) {
        exceptionNotifier.notify(new Error('Unable to find anchor node in _linkActionTip!'));
      }

      // TODO gferrari (12/21/2015): Architectural issue:
      // The 'pathChange' event triggers '_linkActionTip', which dispatches
      // LINK_TIP_OPEN and LINK_TIP_CLOSE.
      // Creating a new RichTextEditor triggers 'pathChange' (through the initial call to
      // setHTML). RichTextEditors are created during STORY_INSERT_BLOCK actions.
      //
      // This means LINK_TIP_OPEN and LINK_TIP_CLOSE actions will be dispatched during STORY_INSERT_BLOCK.
      // This double dispatch is not allowed in the Flux paradigm. As a dirty hack, we're deferring
      // the dispatch of the LINK_TIP_* actions, but this is only justified because the architecturally
      // preferable refactor requires additional deliberation and costing.
      //
      // The root of the issue stems from the fact that RichTextEditor simultaneously implements
      // store behavior (in this case, is the source of truth for app state and app state changes, note 1),
      // and view behavior (deals with presentation and user input, including dispatching actions).
      //
      // A possible improvement to the current RichTextEditor architecture would be to implement
      // a TextEditorStore or maybe a StoryEditStore that exposes enough information for the
      // LinkTipRenderer to determine if it should show itself or not. In this specific case,
      // StoryEditStore could expose a path() getter, to be consumed by LinkTipRenderer.
      //
      // Note 1: getContent, getContentHeight, select, deselect, ....
      _.defer(
        _.bind(function () {
          const rectObject = getBoundingClientRectObject(anchor);
          const state = {
            editorId: this.editorId,
            text: anchor.textContent,
            link: anchor.href,
            openInNewWindow: anchor.getAttribute('target') === '_blank',
            boundingClientRect: rectObject
          };

          StorytellerReduxStore.dispatch(openLinkTip(state));
          // State is still correct here.
        }, this)
      );
    } else {
      // regarding the defer(), see giant comment above.
      _.defer(function () {
        StorytellerReduxStore.dispatch(closeLinkTip());
      });
    }
  }

  private _applyWindowSizeClass() {
    this._callWithContentDocumentIfPresent(
      _.bind(function (contentDocument: Document) {
        const windowSizeClass = windowSizeBreakpointStore.getWindowSizeClass();
        const unusedWindowSizeClasses = windowSizeBreakpointStore.getUnusedWindowSizeClasses();

        $(contentDocument.documentElement)
          .removeClass(unusedWindowSizeClasses.join(' '))
          .addClass(windowSizeClass);
        this.adjustHeight();
      }, this)
    );
  }

  private makeStyleElement(css: string) {
    const style = document.createElement('style');

    style.appendChild(document.createTextNode(css));

    return style;
  }

  private adjustHeight(iframeDocument?: Document) {
    this._updateContentHeight();
    this._shouldBroadcastHeightChange();

    if (iframeDocument && iframeDocument.body) {
      $(iframeDocument.body).css('opacity', 1);
    }
  }

  /**
   * Loads (default & custom) themes into the given document (from an iframe).
   * The theme css is sourced from the styles already present on the
   * main editor window.
   *
   * Please note that this function requires that the document is
   * loaded (i.e., wait for its `load` event).
   */
  private _addThemeStyles(iframeDocument: Document) {
    // Prevent flash of unstyled text by setting opacity to zero
    // and then overriding it in the stylesheet.
    $(iframeDocument.body).css('opacity', 0).addClass('typeset squire-formatted');

    const defaultThemesStyleElement = this.makeStyleElement(this._defaultThemesCss);
    const customThemesStyleElement = this.makeStyleElement(this._customThemesCss);

    defaultThemesStyleElement.onload = this.adjustHeight.bind(null, iframeDocument);

    $(iframeDocument.head).append([defaultThemesStyleElement, customThemesStyleElement]);

    // It takes the browser a few extra frames to render the styles that are
    // applied above with appendChild. If we update the content height
    // willy nilly, we end up with something unrepresentative of the desired
    // height (an unstyled height, if you will). To avoid this, we wait for
    // a seemingly arbitrary amount of time (10ms has been determined
    // sufficient through casual testing) before recalculating the height.
    setTimeout(this.adjustHeight.bind(null, iframeDocument), 10);
  }

  /**
   * Handles changes to content. Changes will be broadcast out of the component
   * via a custom event ('rich-text-editor::content-change').
   */
  private _handleContentChange() {
    this._updateContentHeight();
    this._shouldBroadcastHeightChange();
    this._broadcastContentChangeWhenSettled();
  }

  /**
   * This function is called whenever the content of the editor changes.
   * We need to respond to content changes to adjust the height of the editor
   * element, which is accomplished by calculating the height of the iframe's
   * internal body and then alerting the containing scope of the need to re-
   * render (which will query each text editor for its current height in
   * order to keep the container elements' heights consistent with the heights
   * of the editors' content heights).
   */
  private _updateContentHeight() {
    if (!this._editorBodyElement) {
      return;
    }
    // These calculations have a tendency to be extremely inconsistent
    // if the internal elements and/or the body have both a top and bottom
    // margin or padding. By adding a margin-bottom to block-level elements
    // and a padding-top to the body itself the browser's layout seems to
    // become a lot more consistent, which translates directly into the
    // height the iframe's content being consistently calculated correctly.
    //
    // I have no idea why having both a top and bottom modifier on the layout
    // of block elements causes things to get so fiddly.
    let contentHeight = parseFloat(this._editorBodyElement.css('padding-top')) || 0;

    // We need to recalculate the height of each individual element rather
    // than just checking the outerHeight of the body because the body
    // height has a tendency of getting out of sync with the visible height
    // of its child elements when you, e.g., add a new line and then delete
    // it. Weird, I know.
    this._editorBodyElement.children().each(function () {
      let marginTop;
      let siblingMarginCollapsing = 0;

      const previous = $(this).prev();

      // If we have a previous sibling, we need to take into account
      // margin collapsing ("Adjacent sibling" case only).
      // See https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing.
      if (previous) {
        marginTop = parseFloat($(this).css('margin-top')) || 0;
        siblingMarginCollapsing = parseFloat(previous.css('margin-bottom')) || 0;
        siblingMarginCollapsing = Math.min(marginTop, siblingMarginCollapsing);
      }

      // This needs to be called twice due to a Firefox bug where the first time
      // it's called, sometimes the margins are actually excluded. This may be
      // due to the fact that `outerHeight` can cause a reflow of the page, and
      // Firefox measures the height before doing the reflow. It's unclear.
      let heightIncludingMargins = $(this).outerHeight(true) || 0;
      heightIncludingMargins = $(this).outerHeight(true) || 0;

      contentHeight += heightIncludingMargins - siblingMarginCollapsing;
    });

    // This needs to update both contentHeight and height of the editor(iframe container)
    // This gets triggered when new component is dragged and dropped
    if (contentHeight !== this._lastContentHeight) {
      this._lastContentHeight = contentHeight;
      this._editorElement?.height(contentHeight);
    }
  }

  private _emitEvent(name: string, payload?: any) {
    const eventDetail: { [id: string]: string } = {
      id: this.editorId
    };

    if (typeof payload === 'object') {
      for (const prop in payload) {
        if (prop !== 'id' && payload.hasOwnProperty(prop)) {
          eventDetail[prop] = payload[prop];
        }
      }
    }
    this._editorElement &&
      this._editorElement[0].dispatchEvent(new CustomEvent(name, { detail: eventDetail, bubbles: true }));
  }

  private _shouldBroadcastHeightChange() {
    const newLayoutHeight = this._calculateLayoutHeight();
    // We want to prevent the layout height dropping to zero when the editor is empty.
    // Also prevent constantly re-broadcasting when the height is < MIN_LAYOUT_HEIGHT and the user is typing.
    // We do NOT check whether the height has changed here,
    // because sometimes the subscribers will get out of sync.
    if (
      !this._disableHeightChange &&
      (this._lastLayoutHeight > MIN_LAYOUT_HEIGHT || newLayoutHeight >= MIN_LAYOUT_HEIGHT)
    ) {
      this._lastLayoutHeight = newLayoutHeight >= MIN_LAYOUT_HEIGHT ? newLayoutHeight : MIN_LAYOUT_HEIGHT;
      this._emitEvent('rich-text-editor::height-change', {
        layoutHeight: this._lastLayoutHeight
      });
    }
  }

  /**
   * Broadcast rich-text-editor::content-change when this function stops being called for a while.
   * Ideally we'd know exactly when it's safe to broadcast this event, but unfortunately different
   * input scenarios (typing, pasting, dragging, browser-level undo-redo) have completely different
   * ordering/presence of squire events (input, willPaste, drop, etc). This wouldn't be a problem, but
   * we can't leak intermediate unsanitized content (otherwise it would show up in undo/redo buffers).
   * Instead of maintaining a huge brittle state machine, we bite the async bullet and broadcast
   * the event when things settle down for a frame. This guarantees that all pasting, dropping,
   * typing, and (critically) sanitization have run.
   *
   * Additionally, the browser will "normalize" (lol) any HTML we load into it according to complicated,
   * browser-version-specific rules. Squire will notice these normalizations and raise the
   * generic "change" event. We need to filter out these normalization-only changes, because
   * our undo-redo implementation depends on the 'rich-text-editor::content-change' event to represent
   * a discrete user-driven edit. For example, here is the scenario that drove this implementation:
   *
   * Story with blocks:
   * 1: Plain Text
   * 2: Text with a custom color set in IE (it uses #hex colors, chrome uses rgb).
   *
   * Repro:
   * 1. Load above story in Chrome.
   * 2. Type a character into block 1.
   * 3. Hit Undo once.
   * Expect: Redo button still enabled.
   * Actual: It's disabled.
   *
   * Why? Because Undo triggers squire to normalize block 2 (because chrome converts the color to rgb),
   * which is then seen as a user edit. This blows away the redo stack.
   */
  private _broadcastContentChangeWhenSettled = _.debounce(() => {
    const content = this._editor.getHTML();

    if (content !== this._lastSetContentSquireNormalized) {
      this._emitEvent('rich-text-editor::content-change', {
        content: content,
        editor: this,
        layoutHeight: this._lastLayoutHeight
      });
    }
  }, 1);

  private _broadcastFormatChange() {
    this._emitEvent('rich-text-editor::format-change', {
      content: this._formatController?.getActiveFormats()
    });
  }

  private _broadcastFormatChangeOnArrowKeydown(e: KeyboardEvent) {
    if (
      e.keyCode === 37 || // left arrow key
      e.keyCode === 38 || // up arrow key
      e.keyCode === 39 || // right arrow key
      e.keyCode === 40 // down arrow key
    ) {
      this._emitEvent('rich-text-editor::format-change', {
        content: this._formatController?.getActiveFormats()
      });
    }
  }

  private _broadcastContentClick() {
    this._emitEvent('rich-text-editor::content-click');
  }

  private _broadcastFocus() {
    this._emitEvent('rich-text-editor::focus-change', { isFocused: true });
  }

  private _broadcastBlur() {
    this._emitEvent('rich-text-editor::focus-change', { isFocused: false });
  }

  /**
   * Handles the 'willPaste' event emitted by Squire.
   *
   * The event object will include a `fragment` property which is a
   * document-fragment.
   *
   * e.fragment represents the content that was pasted. By mutating it,
   * we can control what gets inserted into the DOM. We take this opportunity
   * to sanitize the content.
   *
   * @param {Event} e
   *   @prop {DocumentFragment} fragment
   */
  private _sanitizeClipboardInput(e: any) {
    e.fragment = Sanitizer.sanitizeElement(e.fragment);
  }

  /**
   * Passes the content currently in the editor through Sanitizer.
   */
  private _sanitizeCurrentContent() {
    if (this._editorElement === null) {
      return;
    }
    // Get the content and sanitize it.
    const iframe = this._editorElement[0];
    const sanitized = Sanitizer.sanitizeElement(iframe.contentWindow?.document.body);

    // Insert the sanitized content into a div so we can get the HTML.
    const div = document.createElement('div');
    div.appendChild(sanitized.cloneNode(true));

    // Set the new content.
    this._editor.setHTML(div.innerHTML);
  }

  // See: http://stackoverflow.com/a/15318321
  private _setupMouseMoveEventBroadcast(): void {
    if (this._editorElement === null) {
      return;
    }
    const iframe = this._editorElement[0];

    // Save any previous onmousemove handler
    let existingMouseMoveHandler: typeof onmousemove | null;

    if (iframe.hasOwnProperty('contentWindow') && iframe.contentWindow?.hasOwnProperty('onmousemove')) {
      existingMouseMoveHandler = iframe.contentWindow.onmousemove;
    }

    if (iframe.contentWindow) {
      iframe.contentWindow.onmousemove = _.bind(function (e: MouseEvent) {
        // Fire any existing onmousemove listener
        if (existingMouseMoveHandler) {
          this.existingMouseMoveHandler(e);
        }

        // Create a new event for the this window
        const evt = document.createEvent('MouseEvents');

        // We'll need this to offset the mouse move appropriately
        const boundingClientRect = iframe.getBoundingClientRect();

        // Initialize the event, copying exiting event values
        // for the most part
        evt.initMouseEvent(
          'mousemove',
          true, // bubbles
          false, // not cancelable
          window,
          e.detail,
          e.screenX,
          e.screenY,
          e.clientX + boundingClientRect.left,
          e.clientY + boundingClientRect.top,
          e.ctrlKey,
          e.altKey,
          e.shiftKey,
          e.metaKey,
          e.button,
          null // no related element
        );

        // Dispatch the mousemove event on the iframe element
        iframe.dispatchEvent(evt);
      }, iframe.contentWindow);
    }
  }

  // EN-6703 - Better handle iFrame contentDocument errors
  //
  // We see some evidence in Airbrake of various functions in this file
  // attempting to get the contentDocument property of an iFrame that has
  // a different origin than the parent document's. This causes a
  // SecurityError to be raised, and is potentially breaking the user
  // experience.
  //
  // Since we don't know much about the context in which this is happening we
  // are adding a bit of logging in the case of the SecurityError only, and
  // have made all attempts to get the contentDocument property pass through
  // this function so that our logging can be comprehensive.
  //
  // The end result of this is that sometimes we want to proceed with the
  // content document (which is why we pass a callback) and sometimes we don't.
  private _callWithContentDocumentIfPresent(callback: (contentDocument: Document) => void) {
    let contentDocument;
    let errorToNotify;

    function getElementAttributes(el: HTMLIFrameElement | HTMLElement) {
      const attributes = [];
      let attribute;

      for (let i = 0; i < el.attributes.length; i++) {
        attribute = el.attributes[i];

        if (attribute.specified) {
          attributes.push(`"${attribute.name}"="${attribute.value}"`);
        }
      }

      return attributes;
    }

    try {
      if (this._editorElement) {
        contentDocument = this._editorElement[0].contentDocument;
      }
    } catch (error) {
      if (error.name === 'SecurityError' && this._editorElement) {
        // We're not yet sure what is going on with this so we are adding some
        // additional logging around this error.
        const editorElementAttributes = getElementAttributes(this._editorElement[0]).join(', ');
        const editorElementNodeName = this._editorElement[0].parentNode?.nodeName.toLowerCase();
        let editorElementParentNodes;
        if (this._editorElement[0].parentElement) {
          editorElementParentNodes = getElementAttributes(this._editorElement[0].parentElement).join(', ');
        }
        errorToNotify = new Error(
          `SecurityError on iFrame with attributes: "${editorElementAttributes}" ` +
            `and parent node <${editorElementNodeName}> with attributes: "${editorElementParentNodes}".`
        );

        exceptionNotifier.notify(errorToNotify);
      } else {
        throw error;
      }
    }

    if (contentDocument) {
      callback(contentDocument);
    }

    return;
  }
}
