import { forEach, some } from 'lodash';

import { StorytellerState } from 'store/StorytellerReduxStore';
import { COMPONENT_TYPE_GLOBAL_FILTER } from 'types';

import Environment from 'StorytellerEnvironment';

/**
 * Top Level Selectors
 * These selectors are used so that you don't need to have knowledge of how
 * we are combining reducers. Each selector will return the entire
 * state of the reducer
 */
export const blockRemovalConfirmationReducerSelector = (state: StorytellerState) =>
  state.blockRemovalConfirmationReducer;
export const collaboratorsReducerSelector = (state: StorytellerState) => state.collaboratorsReducer;
export const linkTipSliceSelector = (state: StorytellerState) => state.linkTipSlice;
export const storyCopierSliceSelector = (state: StorytellerState) => state.storyCopierSlice;
export const storySaveStatusSelector = (state: StorytellerState) => state.storySaveStatus;
export const storyWithHistorySelector = (state: StorytellerState) => state.storyWithHistory;

export const dataSourceReducerSelector = (state: StorytellerState) =>
  storyWithHistorySelector(state).present.dataSourceReducer;

export const storyReducerSelector = (state: StorytellerState) =>
  storyWithHistorySelector(state).present.storyReducer;
export const storyReducerBlocksSelector = (state: StorytellerState) => storyReducerSelector(state).blocks;
export const storyReducerStoriesSelector = (state: StorytellerState) => storyReducerSelector(state).stories;

export const digestSelector = (state: StorytellerState) => state.digest;

// UI selectors
export const getHasGlobalFilter = (state: StorytellerState) => {
  const blocks = storyReducerBlocksSelector(state);
  let blockIds;
  if (Environment.STORY_UID) {
    blockIds = storyReducerStoriesSelector(state)[Environment.STORY_UID].blockIds;
  }

  const hasGlobalFilter = some(blockIds, (blockId: string) => {
    return blocks[blockId].components[0].type === COMPONENT_TYPE_GLOBAL_FILTER;
  });

  return hasGlobalFilter;
};

export const getGlobalFilterBlockId = (state: StorytellerState) => {
  const blocks = storyReducerBlocksSelector(state);
  let blockIds,
    blockIdForGFB = '';
  if (Environment.STORY_UID) {
    blockIds = storyReducerStoriesSelector(state)[Environment.STORY_UID].blockIds;
  }

  forEach(blockIds, (blockId: string) => {
    if (blocks[blockId].components[0].type === COMPONENT_TYPE_GLOBAL_FILTER) {
      blockIdForGFB = blockId;
    }
  });

  return blockIdForGFB;
};
