import includes from 'lodash/includes';
import { BlockComponent, ComponentType } from 'types';

// Some component types are no longer supported. These will be replaced by
// assetSelector components in the editor.
const unsupported: Array<ComponentType> = ['goal.tile'];

function isComponentUnsupported(component: BlockComponent): boolean {
  return includes(unsupported, component.type);
}

export default isComponentUnsupported;
