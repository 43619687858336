import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import { migrateInSituTables } from 'common/visualizations/helpers/VifHelpers';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import FeatureFlags from 'common/feature_flags';
import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';
import { StoryData, GlobalFilterBlockComponent, COMPONENT_TYPE_GLOBAL_FILTER } from 'types';
import { FilterItemType, ReportDataSource } from 'common/types/reportFilters';
import { ViewFlag } from 'common/types/view';
import { mergeParameterOverrides } from 'lib/ParamUtils';

// In the future, add new migrations to a function, and then call that function here.
/**
 * Migrate a storyData object to the latest version of story JSON. Note that this is executed before all other code
 * in both edit and view mode. If your migration will cause a visual or interaction change to the story, please confirm
 * that it's expected for that migration to take effect without users editing their story.
 * @param storyData This will be mutated with the new storyData
 */
export default async function migrateStoryData(storyData: StoryData) {
  const { blocks, dataSource } = storyData;

  // While we are migrating VIFs, we can also create filterConfigurations and usage metrics to reduce looping.
  const hasFilterParameterConfigurations = dataSource?.hasOwnProperty('filterParameterConfigurations');

  let globalFilterBarComponent: GlobalFilterBlockComponent | undefined;

  for (const block of blocks) {
    const { components } = block;

    for (const component of components) {
      if (component.value?.vif) {
        // migrate all vifs
        const originalVif = component.value.vif;
        let migratedVif = migrateVif(cloneDeep(originalVif));
        const vifType = get(migratedVif, 'series[0].type');

        if (vifType === 'table') {
          // migrate table vif to be compatible with agTable
          migratedVif = await migrateInSituTables(migratedVif);

          set(component, 'type', 'socrata.visualization.agTable');
        }

        set(component, 'value.vif', migratedVif);
      }

      // Finding GFB component for filterConfigurations
      if (component.type === COMPONENT_TYPE_GLOBAL_FILTER) {
        globalFilterBarComponent = component as GlobalFilterBlockComponent;
      }
    }
  }

  // For all stories with a filter bar, we will write filters and parameters
  // to the dataSource.filterParameterConfigurations
  if (
    !hasFilterParameterConfigurations &&
    globalFilterBarComponent !== undefined &&
    storyData.dataSource !== undefined &&
    storyData.dataSource !== null
  ) {
    storyData.dataSource = storyData.dataSource as ReportDataSource;
    const { dataSourceList = [] } = storyData.dataSource;
    storyData.dataSource.filterParameterConfigurations = [];

    for (const { datasetUid, parameterOverrides } of dataSourceList) {
      // Get the parameters for datasetUid
      const metadataProvider = new MetadataProvider({ datasetUid }, true);
      const metadata = await metadataProvider.getDatasetMetadata();

      try {
        // This logic is the same as POPULATE_DATASET_METADATA for fetching parameters
        if ((metadata.flags || []).includes(ViewFlag.SoqlBasedView)) {
          const parameterOverridesMap = new Map(Object.entries(parameterOverrides ?? {}));

          const clientContextVariables = await metadataProvider.getAvailableParameters();

          const datasetParameters = mergeParameterOverrides(parameterOverridesMap, clientContextVariables);
          // Mapping parameters to new parameterConfiguration format
          for (const {
            overrideValue,
            dataType,
            defaultValue,
            displayName,
            name,
            inherited,
            viewId
          } of datasetParameters) {
            storyData.dataSource.filterParameterConfigurations.push({
              type: FilterItemType.PARAMETER,
              config: {
                ...{
                  displayName,
                  isHidden: false,
                  dataType: dataType,
                  overrideValue: overrideValue ?? defaultValue
                },
                paramIds: [
                  {
                    datasetUid,
                    name,
                    ...(inherited && { inheritedFromUid: viewId })
                  }
                ]
              }
            });
          }
        }
      } catch (e) {
        console.error('Failed to fetch parameters for view', datasetUid, e);
      }
    }

    const { filterConfigurations = [] } = storyData.dataSource;
    for (const filter of filterConfigurations) {
      // Mapping filters to new FilterParameterConfiguration format
      storyData.dataSource.filterParameterConfigurations.push({
        type: FilterItemType.FILTER,
        config: filter
      });
    }
  }

  Promise.resolve();
}
