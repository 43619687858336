import $ from 'jquery';

import I18n from 'common/i18n';
import { assert, assertHasProperties, assertHasProperty } from 'common/assertions';

import StorytellerUtils from 'lib/StorytellerUtils';
import './shared/componentBase';
import { StoryTileProps } from './types';
import { StoryTileBlockComponent } from 'types';

const WINDOW_RESIZE_RERENDER_DELAY = 200;

$.fn.componentStoryTile = componentStoryTile;

/*
  Component format:
  {
   type: "story.tile",
   value: {
     domain: <story domain>,
     storyUd: <story four-by-four>
   }
  }
*/

export default function componentStoryTile(props: StoryTileProps) {
  let rerenderOnResizeTimeout: NodeJS.Timeout;
  const $this = $(this);
  const { componentData } = props;

  assertHasProperties(componentData, 'type');
  assert(
    componentData.type === 'story.tile' || componentData.type === 'story.widget',
    `componentStoryTile: Unsupported component type ${componentData.type}`
  );

  function _handleWindowResize() {
    clearTimeout(rerenderOnResizeTimeout);

    rerenderOnResizeTimeout = setTimeout(
      () => renderStoryTile($this, componentData),
      // Add some jitter in order to make sure multiple visualizations are
      // unlikely to all attempt to rerender themselves at the exact same
      // moment.
      WINDOW_RESIZE_RERENDER_DELAY + Math.floor(Math.random() * 10)
    );
  }

  _updateSrc($this, componentData);

  $this.addClass(StorytellerUtils.typeToClassesForComponentType(componentData.type));
  $this.componentBase(props);

  $(window).on('resize', _handleWindowResize);

  $this.on('destroy', () => {
    $(window).off('resize', _handleWindowResize);
    $this.empty();
  });

  return $this;
}

function _updateSrc($element: JQuery, componentData: StoryTileBlockComponent) {
  const renderedStoryTileSrc = $element.attr('data-rendered-story-tile-url');

  assertHasProperties(
    componentData,
    'value.domain',
    'value.storyUid'
  );

  const storyTileSrc = StorytellerUtils.generateStoryTileJsonSrc(
    componentData.value.domain,
    componentData.value.storyUid
  );

  if (renderedStoryTileSrc !== storyTileSrc) {
    // Although we do some basic validation on the user input,
    // since we must support arbitrary customer domains `storyTileSrc`
    // can point to any domain. Accordingly, this is a potential phishing
    // risk.
    $element.attr('data-rendered-story-tile-url', storyTileSrc);

    Promise.resolve($.get(storyTileSrc)).
      then(
        function(storyTileData) {
          renderStoryTile($element, componentData, storyTileData);
        }
      ).
      catch(
        function(error) {
          if (window.console && console.error) {
            console.error(error);
          }

          renderStoryTileError($element);
        }
      );
  }
}

function _updateTextEllipsification($element: JQuery) {
  const renderedResponse = $element.attr('data-rendered-story-tile-data');
  const renderedWidth = parseInt($element.attr('data-rendered-story-tile-width')!, 10);
  const elementWidth = Math.floor($element.outerWidth(true)!);

  if (renderedResponse && renderedWidth !== elementWidth) {
    const storyTileData = JSON.parse(renderedResponse);

    $element.attr('data-rendered-story-tile-width', elementWidth);

    const $tileTitle = $element.find('.story-tile-title');
    $tileTitle.text(storyTileData.title);

    StorytellerUtils.ellipsifyText($tileTitle, 2);
    const $tileDescription = $element.find('.story-tile-description');

    if (storyTileData.description !== null) {
      $tileDescription.text(storyTileData.description);
      StorytellerUtils.ellipsifyText($tileDescription, 3);
    }
  }
}

interface StoryTileData {
  description: string;
  /** URL of the image */
  image: string;
  title: string;
  url: string;
}

function renderStoryTile($element: JQuery, componentData: StoryTileBlockComponent, storyTileData?: StoryTileData) {

  assertHasProperty(componentData, 'type');
  assertHasProperty(componentData, 'value');

  // If there is no story tile data provided we just want to re-ellipsify
  // the text (which we can do by mutating rather than wiping out the entire
  // entire component subtree), but only in the case that the width of
  // the container has changed (doing so more often is not great for
  // performance).
  if (!storyTileData) {
    _updateTextEllipsification($element);
    return;
  }

  removeStoryTile($element);

  $element.attr(
    'data-rendered-story-tile-data',
    JSON.stringify(storyTileData)
  );

  const $tileContainer = $(
    '<a>',
    {
      'href': storyTileData.url,
      'target': componentData.value.openInNewWindow ? '_blank' : '_self',
      'class': 'story-tile-container'
    }
  );

  const $tileContent = $('<div>', { 'class': 'story-tile typeset' });

  const $tileTitle = $('<h2>', { 'class': 'story-tile-title' }).
    text(storyTileData.title);

  const $tileTitleContainer = $('<div>', { 'class': 'story-tile-title-container' }).
    append($tileTitle);

  const $tileImage = $(
    '<div>',
    {
      'class': 'story-tile-image'
    }
  );

  if (storyTileData.image !== null) {
    $tileImage.attr('style', `background-image:url(${storyTileData.image})`);
  }

  const $tileDescription = $('<p>', { 'class': 'story-tile-description' });

  if (storyTileData.description !== null) {
    $tileDescription.text(storyTileData.description);
  }

  const $tileViewStory = $('<div>', { 'class': 'story-tile-view-story', 'aria-label':  I18n.t('editor.story_tile.view_story_label') + storyTileData.title }).
    text(I18n.t('editor.story_tile.view_story_prompt'));

  $tileContent.append([
    $tileTitleContainer,
    $tileImage,
    $tileDescription,
    $tileViewStory
  ]);

  $tileContainer.append($tileContent);

  $element.append($tileContainer);

  StorytellerUtils.ellipsifyText($tileTitle, 2);

  if ($tileDescription) {
    StorytellerUtils.ellipsifyText($tileDescription, 3);
  }

  $tileContainer.addClass('rendered');
}

function removeStoryTile($element: JQuery) {
  $element.
    removeClass('component-error').
    children().
    // Don't accidentally remove the edit control when trying to clear the
    // component's DOM tree in order to re-render it.
    not('.component-edit-controls-container').
    remove();
}

function renderStoryTileError($element: JQuery) {
  $element.
    children().
    not('.component-edit-controls-container').
    remove();

  $element.
    addClass('component-error').
    append([
      $('<p>').text(I18n.t('editor.story_tile.invalid_permissions'))
    ]);
}
