import $ from 'jquery';
import { extend } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import I18n from 'common/i18n';
import { assert, assertHasProperty } from 'common/assertions';

import './shared/componentBase';
import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';
import { MeasureProps } from './types';
import { COMPONENT_TYPE_MEASURE_CARD, COMPONENT_TYPE_MEASURE_CHART } from 'types';

import ComponentMeasure, { MeasurePropsReact } from './componentMeasure/ComponentMeasure';

$.fn.componentMeasure = componentMeasure;

// This component supports measure.card and measure.chart.
export default function componentMeasure(props: MeasureProps) {
  assertHasProperty(props, 'componentData.type');
  const componentType = props.componentData.type;

  assert(
    componentType === COMPONENT_TYPE_MEASURE_CHART || componentType === COMPONENT_TYPE_MEASURE_CARD,
    `componentMeasure: Unsupported component type ${componentType}`
  );

  props = extend({}, props, {
    isFilterableAsset: true,
    defaultHeight: Constants.DEFAULT_VISUALIZATION_HEIGHT,
    resizeSupported: componentType === COMPONENT_TYPE_MEASURE_CHART
  });

  const $this = $(this);

  if ($this.children().length === 0) {
    renderTemplate($this);
  }

  const $componentContent = $this.find('.component-content');

  const classes = StorytellerUtils.typeToClassesForComponentType(componentType);

  // If the component doesn't currently have the correct classes for the type of measure,
  // it means the user has switched the measure view type and we must update the classes.
  if (!$componentContent.parent().hasClass(classes)) {
    const classesToRemove = StorytellerUtils.typeToClassesForComponentType(
      componentType === COMPONENT_TYPE_MEASURE_CHART
        ? COMPONENT_TYPE_MEASURE_CARD
        : COMPONENT_TYPE_MEASURE_CHART
    );
    $componentContent.parent().removeClass(classesToRemove);
    $componentContent.parent().addClass(classes);
  }

  const componentMeasureProps: MeasurePropsReact = {
    ...props,
    jQueryErrorCallback: (error: boolean) => $this.toggleClass('load-error', error)
  };
  ReactDOM.render(<ComponentMeasure {...componentMeasureProps} />, $componentContent[0]);

  $this.componentBase(props);
  return $this;
}

function renderTemplate($element: JQuery) {
  const $componentContent = $('<div>', { class: 'component-content' });
  const $componentError = $('<div>', { class: 'component-error' });
  $componentError.text(I18n.t('editor.components.performance_measures.generic_error'));
  $element.append($componentContent).append($componentError);
}
