// Linter migration tech debt.
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */

import $ from 'jquery';
import _ from 'lodash';

import { assert, assertHasProperties, assertHasProperty } from 'common/assertions';
import HTTPError from 'common/errors/HTTPError';
import parseJsonOrEmpty from 'common/js_utils/parseJsonOrEmpty';
import { VisualizationRenderer } from 'common/visualizations';
import { MetadataProvider } from 'common/visualizations/dataProviders';
import { getVifSeriesDatasets } from 'common/visualizations/helpers/VifHelpers';
import I18n from 'common/i18n';
import { View } from 'common/types/view';
import { Vif } from 'common/visualizations/vif';

import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';
import { updateVifWithDefaults, prepareVifForRender } from 'lib/VifUtils';
import { ComponentBaseType } from './shared/componentBase';
import { isFlexibleStory } from 'lib/FlexibleLayoutUtils';
import './shared/componentBase';

import { VisualizationCanvasProps } from './types';

$.fn.componentSocrataVisualizationVizCanvas = componentSocrataVisualizationVizCanvas;

/*
  Component format:
  {
    type: "socrata.visualization.vizCanvas",
    value: {
      dataset: {
        datasetUid: ...
        vifId: ...
      }
    }
  }
*/
let componentBase: ComponentBaseType;

export default function componentSocrataVisualizationVizCanvas(props: VisualizationCanvasProps) {
  _.defaults(props, {
    isFilterableAsset: true,
    resizeOptions: {
      minHeight: Constants.MINIMUM_COMPONENT_HEIGHTS_PX.VISUALIZATION
    },
    resizeSupported: !isFlexibleStory(),
    useMetadataCache: true // We want to cache requests for metadata, but not during test runs
  });

  const $this = $(this);
  const { componentData } = props;

  assertHasProperty(componentData, 'type');
  assert(
    componentData.type === 'socrata.visualization.vizCanvas',
    `componentSocrataVisualizationVizCanvas: Unsupported component type ${componentData.type}`
  );

  if ($this.children().length === 0) {
    _renderTemplate($this, props);
  }

  if (isFlexibleStory()) {
    props.defaultHeight = Constants.FLEXIBLE_MINIMUM_COMPONENT_HEIGHT_PX.VISUALIZATION;
  }

  componentBase = $this.componentBase(props);
  _updateVisualization($this, props);

  return $this;
}

function _findMatchingVifInView(view: View, vifId: string): Vif {
  assertHasProperty(view, 'displayFormat.visualizationCanvasMetadata.vifs');
  const vifs = view.displayFormat?.visualizationCanvasMetadata?.vifs;
  const vif = _.find(vifs, { id: vifId }) as Vif;

  // Core's serialization strips out any properties that have null or "empty"
  // values. The vif has properties whose values are intentionally null, not including
  // them will prevent the visualization from rendering. We handle this for embeds
  // and Viz Cans in frontend/app/models/display_format.rb#restore_required_vif_metadata
  const measure = vif.series[0].dataSource.measure;
  if (measure) {
    measure.aggregationFunction ||= null;
  }

  return vif;
}

async function _getVif(
  visualizationConfig: { datasetUid: string; vifId: string },
  useMetadataCache: boolean
): Promise<Vif> {
  const { datasetUid, vifId } = visualizationConfig;
  const metadataProvider = new MetadataProvider({ datasetUid }, useMetadataCache);
  const datasetMetadataAndFederationStatus = await metadataProvider.getDatasetMetadataAndFederationStatus();
  const view = datasetMetadataAndFederationStatus.metadata;
  const vif = _findMatchingVifInView(view, vifId);
  if (!vif) {
    throw new HTTPError(404, `Could not find VIF with id ${vifId} in the view.`);
  }

  // Set vif.origin.url to point to the vizcan asset on the appropriate domain
  const attributionDomain = await metadataProvider.getAttributionDomain(datasetMetadataAndFederationStatus);
  if (attributionDomain) {
    _.set(vif, 'origin.url', `https://${attributionDomain}/d/${datasetUid}`);
    _.set(vif, 'origin.title', view.name);
  }

  return vif;
}

function _renderTemplate($element: JQuery, props: VisualizationCanvasProps) {
  const { componentData } = props;

  assertHasProperties(componentData, 'value.dataset.datasetUid', 'value.dataset.vifId');

  const { type } = componentData;
  const classes = StorytellerUtils.typeToClassesForComponentType(type);
  const $componentContent = $('<div>', { class: 'component-content' });

  $element
    .addClass(classes)
    .on('destroy', () => {
      $componentContent.triggerHandler('SOCRATA_VISUALIZATION_DESTROY');
    })
    .append($componentContent);
}

function _updateVisualization($element: JQuery, props: VisualizationCanvasProps) {
  const { componentData, useMetadataCache, additionalFilters, parameterOverrides } = props;

  assertHasProperties(componentData, 'value.dataset.datasetUid', 'value.dataset.vifId');

  function _renderVisualization(newVif: Vif) {
    prepareVifForRender(
      $element,
      newVif,
      additionalFilters,
      (updatedVif) => {
        $componentContent.triggerHandler('SOCRATA_VISUALIZATION_DESTROY');

        // eslint-disable-next-line no-new
        new VisualizationRenderer(updateVifWithDefaults(updatedVif), $componentContent, {
          displayFilterBar: true,
          toggleMapLayersInternally: true
        });
      },
      parameterOverrides
    );
  }

  function _renderError() {
    $('.socrata-visualization-error', $element).remove();

    const errorMessage = I18n.t('editor.viz_canvas.errors.status');
    const containerHeight = `${_.get(
      componentData,
      'value.layout.height',
      props.resizeOptions?.minHeight
    )}px`;
    // Fake the visualization's internal error rendering
    const $errorMessageElement = $(`
      <div style="height: ${containerHeight}" class="socrata-visualization socrata-visualization-error">
        <div class="socrata-visualization-error-container error light">
          <span class="socrata-visualization-error-message text">
            ${errorMessage}
          </span>
        </div>
      </div>
    `);

    $componentContent.append($errorMessageElement);
  }

  const renderedVisualizationConfig = parseJsonOrEmpty($element.attr('data-rendered-visualization'));
  const $componentContent = $element.find('.component-content');
  const visualizationConfig = componentData.value.dataset!;
  const configsAreEquivalent = _.isEqual(renderedVisualizationConfig, visualizationConfig);
  // Re-fetch viz-canvas view and associated vif if any part of the block configuration changes
  // else re-render the vizualization from the previously rendered vif
  if (!configsAreEquivalent) {
    _getVif(visualizationConfig, useMetadataCache)
      .then((vif: Vif) => {
        $element.attr('data-rendered-visualization', JSON.stringify(visualizationConfig));
        const dataSources = getVifSeriesDatasets(vif);
        $element.attr('data-rendered-data-sources', JSON.stringify(dataSources));
        componentBase.updateDataSources($element, dataSources);
        _renderVisualization(vif);
      })
      .catch((error) => {
        console.error('Failed to get view for configured visualization: ', visualizationConfig);
        console.error(error);
        _renderError();
      });
  } else {
    try {
      const renderedVif = JSON.parse($element.attr('data-rendered-vif')!);
      const dataSources = getVifSeriesDatasets(renderedVif);
      componentBase.updateDataSources($element, dataSources);
      _renderVisualization(renderedVif);
    } catch (error) {
      console.error('Failed to parse previously rendered vif:', error);
      _renderError();
    }
  }
}
