import _ from 'lodash';
import { SoqlFilter, FILTER_FUNCTION } from 'common/components/SingleSourceFilterBar/SoqlFilter';

import { GlobalFilters } from 'types';

/**
 * @param additionalFilters
 * @param condition Function to call for each filter. Returns whether or not the filter
 *                  should be included in the result.
 */
export const getFiltersMatchingCondition = (
  additionalFilters: GlobalFilters,
  condition: (filter: SoqlFilter) => boolean
): GlobalFilters =>
  _.reduce(
    additionalFilters,
    (allMatchingFilters, filters, datasetUid) => {
      const matchingFilters = _.filter(filters, condition);

      if (matchingFilters.length > 0) {
        allMatchingFilters[datasetUid] = matchingFilters;
      }

      return allMatchingFilters;
    },
    {}
  );

/**
 * Only filters that have an applied value.
 */
export const getOperationalFilters = (additionalFilters: GlobalFilters): GlobalFilters => {
  return getFiltersMatchingCondition(
    additionalFilters,
    (filter: SoqlFilter) => filter.function !== FILTER_FUNCTION.NOOP
  );
};

/**
 * Whether the two filter objects would have the same effect.
 */
export const filtersAreEquivalent = (filters: GlobalFilters, newFilters: GlobalFilters): boolean => {
  return _.isEqual(getOperationalFilters(filters), getOperationalFilters(newFilters));
};
