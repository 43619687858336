import {
  ComponentProps,
  ClassicVisualizationProps,
  EmbeddedHTMLProps,
  FeatureMapProps,
  GlobalFilterProps,
  HeroProps,
  HTMLProps,
  InSituVisualizationProps,
  MapProps,
  MeasureProps,
  RegionMapProps,
  StoryTileProps,
  TableProps,
  VisualizationCanvasProps
} from 'lib/components/block-component-renderers/types';
import { isFlexibleStory } from 'lib/FlexibleLayoutUtils';

import {
  COMPONENT_TYPE_AG_TABLE,
  COMPONENT_TYPE_BAR_CHART,
  COMPONENT_TYPE_CHOROPLETH_MAP,
  COMPONENT_TYPE_CLASSIC_VISUALIZATION,
  COMPONENT_TYPE_CALENDAR,
  COMPONENT_TYPE_COLUMN_CHART,
  COMPONENT_TYPE_COMBO_CHART,
  COMPONENT_TYPE_EMBEDDED_HTML,
  COMPONENT_TYPE_FEATURE_MAP,
  COMPONENT_TYPE_GLOBAL_FILTER,
  COMPONENT_TYPE_HERO,
  COMPONENT_TYPE_HISTOGRAM,
  COMPONENT_TYPE_HTML,
  COMPONENT_TYPE_IMAGE,
  COMPONENT_TYPE_MAP,
  COMPONENT_TYPE_MEASURE_CARD,
  COMPONENT_TYPE_MEASURE_CHART,
  COMPONENT_TYPE_PIE_CHART,
  COMPONENT_TYPE_REGION_MAP,
  COMPONENT_TYPE_SCATTER_CHART,
  COMPONENT_TYPE_STORY_TILE,
  COMPONENT_TYPE_STORY_WIDGET,
  COMPONENT_TYPE_TABLE,
  COMPONENT_TYPE_TIMELINE_CHART,
  COMPONENT_TYPE_VIZ_CANVAS
} from 'types';

export interface RenderStoryComponentArgs {
  $element: JQuery<HTMLElement>;
  elementProps:
    | ComponentProps
    | ClassicVisualizationProps
    | EmbeddedHTMLProps
    | FeatureMapProps
    | GlobalFilterProps
    | HeroProps
    | HTMLProps
    | InSituVisualizationProps
    | MeasureProps
    | MapProps
    | RegionMapProps
    | StoryTileProps
    | TableProps
    | VisualizationCanvasProps;
}

export const renderStoryComponent = (args: RenderStoryComponentArgs) => {
  const { $element, elementProps } = args;

  switch (elementProps.componentData.type) {
    case COMPONENT_TYPE_HERO:
      $element.componentHero(elementProps as HeroProps);
      break;

    case COMPONENT_TYPE_STORY_TILE:
    case COMPONENT_TYPE_STORY_WIDGET:
      $element.componentStoryTile(elementProps as StoryTileProps);
      break;

    case COMPONENT_TYPE_MEASURE_CARD:
    case COMPONENT_TYPE_MEASURE_CHART:
      $element.componentMeasure(elementProps as MeasureProps);
      break;

    case COMPONENT_TYPE_CLASSIC_VISUALIZATION:
      $element.componentSocrataVisualizationClassic(elementProps as ClassicVisualizationProps);
      break;

    case COMPONENT_TYPE_REGION_MAP:
    case COMPONENT_TYPE_CHOROPLETH_MAP:
      $element.componentSocrataVisualizationRegionMap(elementProps as RegionMapProps);
      break;

    case COMPONENT_TYPE_BAR_CHART:
    case COMPONENT_TYPE_CALENDAR:
    case COMPONENT_TYPE_COLUMN_CHART:
    case COMPONENT_TYPE_COMBO_CHART:
    case COMPONENT_TYPE_HISTOGRAM:
    case COMPONENT_TYPE_SCATTER_CHART:
    case COMPONENT_TYPE_PIE_CHART:
    case COMPONENT_TYPE_TIMELINE_CHART:
      $element.componentSocrataVisualizationInSitu(elementProps as InSituVisualizationProps);
      break;

    case COMPONENT_TYPE_AG_TABLE:
    case COMPONENT_TYPE_TABLE:
      $element.componentSocrataVisualizationTable(elementProps as TableProps);
      break;

    case COMPONENT_TYPE_FEATURE_MAP:
      $element.componentSocrataVisualizationFeatureMap(elementProps as FeatureMapProps);
      break;

    case COMPONENT_TYPE_MAP:
      $element.componentSocrataVisualizationMap(elementProps as MapProps);
      break;

    case COMPONENT_TYPE_VIZ_CANVAS:
      $element.componentSocrataVisualizationVizCanvas(elementProps as VisualizationCanvasProps);
      break;

    case COMPONENT_TYPE_EMBEDDED_HTML:
      $element.componentEmbeddedHtml(elementProps as EmbeddedHTMLProps);
      break;

    case COMPONENT_TYPE_IMAGE:
      $element.componentImage(elementProps);
      break;

    case COMPONENT_TYPE_GLOBAL_FILTER:
      $element.componentGlobalFilter(elementProps as GlobalFilterProps);
      break;

    case COMPONENT_TYPE_HTML:
      // For flexible layouts, we render componentHTML (aka text components)
      // through the jquery plugin instead of Rails.
      if (isFlexibleStory()) {
        $element.componentHTML(elementProps as HTMLProps);
      }
      break;

    default:
      $element.componentBase(elementProps);
      break;
  }
};
