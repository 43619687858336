import { createReducer, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  cloneDeep,
  difference,
  forEach,
  get,
  includes,
  intersection,
  isEmpty,
  isEqual,
  keyBy,
  merge
} from 'lodash';

import { getCurrentDomain } from 'common/currentDomain';
import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';
import { ViewFlag } from 'common/types/view';
import { mergeParameterOverrides } from 'lib/ParamUtils';
import migrateFilter from 'common/components/FilterBar/lib/migrateFilter';

import { StoryData, FluxPayload, GlobalFilters } from 'types';
import { SoqlFilter, FILTER_FUNCTION, DataSourceColumn } from 'common/components/FilterBar/SoqlFilter';
import {
  DataSourceCCVs,
  DataSourceMetadata,
  DataSourceParameter,
  FilterItemType,
  FilterParameterConfiguration,
  ParameterConfiguration,
  ReportDataSource,
  SingleDataSource
} from 'common/types/reportFilters';
import { Filters } from 'common/components/SingleSourceFilterBar/types';
import Actions from 'Actions';
import {
  removeGlobalFilter,
  updateStoryDataSource,
  addFilterParameterConfiguration,
  deleteFilterParameterConfiguration,
  removeStoryDataSource,
  updateParameterOverrides,
  updateFilterParameterConfiguration,
  updateAllFilterParameterConfigurations
} from 'store/TopLevelActions';
import { refreshRequiredDataSources } from 'store/TopLevelActions';
import { selectors } from 'store/selectors/DataSourceSelectors';
import type { StorytellerState } from 'store/StorytellerReduxStore';

export interface DataSourceReducerState {
  // Single source stories
  globalFilters?: {
    [datasetUid: string]: Filters;
  };

  // Multi source stories
  dataSourceList?: SingleDataSource[];
  filterConfigurations?: SoqlFilter[];
  filterParameterConfigurations?: FilterParameterConfiguration[];
  dataSourceMetadata?: DataSourceMetadata;
  parameters?: DataSourceCCVs;
  /** Data source uids */
  requiredDataSources?: string[];
  isNewRequiredSourcesAdded?: boolean;
  showLoadingIndicator?: boolean;
  filtersMigrated?: boolean;
}

const initialState = {
  requiredDataSources: [],
  isNewRequiredSourcesAdded: false,
  showLoadingIndicator: false
} as DataSourceReducerState;

interface MetadataResults {
  metadata: DataSourceMetadata;
  parameters: DataSourceCCVs;
}

/* Action Creators */
export const populateGlobalFilter = createAction<FluxPayload>(Actions.POPULATE_GLOBAL_FILTER);
export const populateDatasetMetadata = createAsyncThunk<MetadataResults, void, { state: StorytellerState }>(
  Actions.POPULATE_DATASET_METADATA,
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const parameterOverrides = selectors.getParameterOverrides(state);
    const allMetadata: DataSourceMetadata = {};
    const parameters: DataSourceCCVs = {};

    const dataSourceUids = selectors.getDataSourceUids(state);

    for (const datasetUid of dataSourceUids) {
      const provider = new MetadataProvider({ datasetUid }, true);
      const { metadata, federatedFrom } = await provider.getDatasetMetadataAndFederationStatus();
      const filterableColumns = await provider.getDisplayableFilterableColumns({
        datasetMetadata: metadata,
        shouldGetColumnStats: false
      });
      allMetadata[datasetUid] = {
        ...metadata,
        domainCName: federatedFrom ?? getCurrentDomain(),
        columns: keyBy(filterableColumns, 'fieldName')
      };

      try {
        if (includes(metadata.flags || [], ViewFlag.SoqlBasedView)) {
          const clientContextVariables = await provider.getAvailableParameters();
          const datasetParameters = mergeParameterOverrides(
            parameterOverrides[datasetUid],
            clientContextVariables
          );
          parameters[datasetUid] = [...datasetParameters];
        }
      } catch (e) {
        // MetadataProvider does not handle all federation cases, and may cause an error.
        // For now we can ignore these.
        console.error('Failed to fetch parameters for view', datasetUid, e);
      }
    }
    return { metadata: allMetadata, parameters };
  }
);

export const storySavedAfterNewSourcesAdded = createAction<void>(
  Actions.GLOBAL_FILTER_SAVE_STORY_AFTER_NEW_SOURCES_ADDED
);

/* Reducer */

const dataSourceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(populateGlobalFilter, (state: DataSourceReducerState, action) => {
      const storyData = action.payload as StoryData;
      populateReducerFromStoryData(state, storyData);
    })
    .addCase(removeGlobalFilter, (state) => {
      // Notes for when we remove Flux store and autosave from Redux actions
      // GFB can be removed in two ways -
      // 1. Removing a block that contains GFB
      // 2. Applying a new template
      // Both cases should trigger an autosave from different actions, so we do not need to trigger autosave here.

      // Reset data source list to the list of required data sources.
      state.dataSourceList = (state.requiredDataSources ?? []).map((datasetUid: string) => ({
        datasetUid,
        parameterOverrides: {}
      }));

      state.filterParameterConfigurations = [];
    })
    .addCase(removeStoryDataSource, (state, action) => {
      const { payload } = action;
      const { datasetUid } = payload;

      if (state.hasOwnProperty('filterParameterConfigurations')) {
        const newDataSourceList = (state.dataSourceList ?? []).filter(
          (ds: SingleDataSource) => ds.datasetUid !== datasetUid
        );
        state.dataSourceList = newDataSourceList;

        const newFilterParameterConfigurations = (state.filterParameterConfigurations ?? [])
          .map((config: FilterParameterConfiguration) => {
            if (config.type === FilterItemType.PARAMETER) {
              const paramConfig = config.config as ParameterConfiguration;
              const newParamIds = paramConfig.paramIds.filter(
                ({ datasetUid: paramDatasetUid }) => paramDatasetUid !== datasetUid
              );

              if (newParamIds.length === 0) {
                return null;
              }
              let newParameterConfig = { ...paramConfig, paramIds: newParamIds };

              newParameterConfig = updateConfigWhenParameterRemoved(
                state,
                newParamIds,
                paramConfig,
                newParameterConfig
              );

              return { ...config, config: newParameterConfig };
            } else {
              const filterConfig = config.config as SoqlFilter;
              const newColumns = filterConfig.columns.filter((column) => column.datasetUid !== datasetUid);
              let newFilter = { ...filterConfig, columns: newColumns };

              if (newColumns.length === 0) {
                return null;
              }

              newFilter = updateFilterWhenColumnRemoved(state, newColumns, filterConfig, newFilter);

              return { ...config, config: newFilter };
            }
          })
          .filter((config: FilterParameterConfiguration) => config);
        state.filterParameterConfigurations = newFilterParameterConfigurations;
      }
      const newDataSourceList = (state.dataSourceList ?? []).filter(
        (ds: SingleDataSource) => ds.datasetUid !== datasetUid
      );
      state.dataSourceList = newDataSourceList;
    })
    .addCase(updateStoryDataSource, (state, action) => {
      const { payload } = action;
      const { datasetUid } = payload;

      const newSingleDataSource = {
        datasetUid: datasetUid
      };

      if (!state.dataSourceList || state.dataSourceList.length === 0) {
        state.dataSourceList = [newSingleDataSource];
      } else if (!datasetInDataSourceList(state.dataSourceList, datasetUid)) {
        state.dataSourceList = [...state.dataSourceList, newSingleDataSource];
      } else {
        const newList = state.dataSourceList.map((dataSource: SingleDataSource) => {
          if (dataSource.datasetUid === newSingleDataSource.datasetUid) {
            return newSingleDataSource;
          }

          return dataSource;
        });
        state.dataSourceList = newList;
      }
    })
    .addCase(updateParameterOverrides, (state, action) => {
      const { payload } = action;
      const { dataSourceList } = payload;
      const mergeArraysByDatasetUid = (oldList: SingleDataSource[], newList: SingleDataSource[]) => {
        const oldDataSourceList = cloneDeep(oldList);
        return oldDataSourceList.map((singleDS) => {
          return { ...singleDS, ...newList.find((ds) => ds.datasetUid === singleDS.datasetUid) };
        });
      };

      state.dataSourceList = mergeArraysByDatasetUid(state.dataSourceList, dataSourceList);

      if (state.parameters) {
        // Merge new parameter overrides into existing saved parameters
        forEach(dataSourceList, (dataSource) => {
          state.parameters[dataSource.datasetUid] = mergeParameterOverrides(
            new Map(Object.entries(dataSource.parameterOverrides ?? {})),
            state.parameters[dataSource.datasetUid]
          );
        });
      }
    })
    .addCase(addFilterParameterConfiguration, (state, action) => {
      const { payload } = action;
      const { filterParameterConfiguration } = payload;

      const currentParameterConfigurations = state.filterParameterConfigurations || [];
      const newParameterConfigurations = [...currentParameterConfigurations, filterParameterConfiguration];

      state.filterParameterConfigurations = newParameterConfigurations;
    })
    .addCase(deleteFilterParameterConfiguration, (state, action) => {
      const { payload } = action;
      const { index } = payload;

      state.filterParameterConfigurations = (state.filterParameterConfigurations ?? []).filter(
        (item: FilterParameterConfiguration, i: number) => i !== index
      );
    })
    .addCase(updateFilterParameterConfiguration, (state, action) => {
      const { payload } = action;
      const { index, updatedConfig } = payload;

      if (state.filterParameterConfigurations && !isEmpty(state.filterParameterConfigurations[index])) {
        state.filterParameterConfigurations[index] = updatedConfig;
      }
    })
    .addCase(updateAllFilterParameterConfigurations, (state, action) => {
      const { payload } = action;
      const { updatedConfigs } = payload;

      if (state.filterParameterConfigurations) {
        state.filterParameterConfigurations = updatedConfigs;
      }
    })
    .addCase(populateDatasetMetadata.fulfilled, (state, action) => {
      const dataSourceMetadata = action.payload.metadata;
      const dataSourceParameters = action.payload.parameters;
      state.dataSourceMetadata = merge(state.dataSourceMetadata, dataSourceMetadata);
      state.parameters = merge(state.parameters, dataSourceParameters);
      state.showLoadingIndicator = false;

      if (!state.filtersMigrated) {
        state.filtersMigrated = true;
        const columnsMetadataForFilter = (soqlFilter: SoqlFilter) => {
          const columnsMetadata = {};
          (soqlFilter.columns ?? []).forEach(({ datasetUid, fieldName }) => {
            columnsMetadata[datasetUid] = get(
              state.dataSourceMetadata,
              [datasetUid, 'columns', fieldName],
              {}
            );
          });
          return columnsMetadata;
        };

        if (state.hasOwnProperty('filterParameterConfigurations')) {
          state.filterParameterConfigurations = (state.filterParameterConfigurations || []).map(
            ({ type, config }: FilterParameterConfiguration) => {
              if (type === FilterItemType.FILTER) {
                return {
                  type: FilterItemType.FILTER,
                  config: migrateFilter(config as SoqlFilter, columnsMetadataForFilter(config as SoqlFilter))
                };
              }

              return { type, config };
            }
          );
        }
      }
    })
    .addCase(refreshRequiredDataSources.fulfilled, (state, action) => {
      const { payload } = action;
      const { requiredDataSources = [] } = payload;

      // If there is a difference in current and incoming required data sources, save to the state.
      requiredDataSources.sort();
      const currentRequiredDataSourcesSorted = [...(state.requiredDataSources || [])].sort();
      if (!isEqual(requiredDataSources, currentRequiredDataSourcesSorted)) {
        state.requiredDataSources = requiredDataSources;
      }

      // Check to see if new required data sources are included in current data source list. If not, add them.
      const currentDataSources: string[] = (state.dataSourceList || []).map(
        (ds: SingleDataSource) => ds.datasetUid
      );
      const dataSourcesToAdd = difference(requiredDataSources, currentDataSources);

      if (dataSourcesToAdd.length > 0) {
        // If we need to fetch the metadata for new data sources, the data source list will take longer to
        // render so show a loading indicator.
        if (
          intersection(Object.keys(state.dataSourceMetadata), dataSourcesToAdd).length !=
          dataSourcesToAdd.length
        ) {
          state.showLoadingIndicator = true;
        }
        const newDataSourceList = cloneDeep(state.dataSourceList || []);
        dataSourcesToAdd.forEach((datasetUid: string) => {
          newDataSourceList.push({ datasetUid, parameterOverrides: {} });
        });

        state.dataSourceList = newDataSourceList;
        state.isNewRequiredSourcesAdded = true;
      }
    })
    .addCase(storySavedAfterNewSourcesAdded, (state) => {
      state.isNewRequiredSourcesAdded = false;
    });
});

export const datasetInDataSourceList = (dataSourceList: [SingleDataSource], datasetUid: string) => {
  let found = false;
  dataSourceList.forEach((ds: SingleDataSource) => {
    if (ds.datasetUid === datasetUid) {
      found = true;
    }
  });
  return found;
};

const updateConfigWhenParameterRemoved = (
  state: DataSourceReducerState,
  newParameters: DataSourceParameter[],
  oldParameterConfig: ParameterConfiguration,
  newParameterConfig: ParameterConfiguration
) => {
  // If the first parameter is different (i.e., it was removed because its data source was removed),
  // update the filter's display name.
  // We may want to remove this logic in EN-59603 when filters can be named by the user.
  if (newParameters.length > 0 && !isEqual(newParameters[0], oldParameterConfig.paramIds[0])) {
    if (state.parameters) {
      const newDisplayName = state.parameters[newParameters[0].datasetUid].find((ccv) => {
        return ccv.name === newParameters[0].name;
      })?.displayName;

      newParameterConfig.displayName = newDisplayName || newParameters[0].name;
    } else {
      newParameterConfig.displayName = newParameters[0].name;
    }
  }

  return newParameterConfig;
};

const updateFilterWhenColumnRemoved = (
  state: DataSourceReducerState,
  newColumns: DataSourceColumn[],
  oldFilter: SoqlFilter,
  newFilter: SoqlFilter
): SoqlFilter => {
  // If the first column is different (i.e., it was removed because its data source was removed),
  // update the filter's display name.
  // We may want to remove this logic in EN-59603 when filters can be named by the user.
  if (newColumns.length > 0 && !isEqual(newColumns[0], oldFilter.columns[0])) {
    newFilter.displayName = undefined;
  }

  // If we unlinked a column from this filter, we need to reset the filter values.
  if (oldFilter.columns.length > newColumns.length) {
    newFilter.arguments = null;
    newFilter.function = FILTER_FUNCTION.NOOP;
  }

  return newFilter;
};

const populateReducerFromStoryData = (state: DataSourceReducerState, storyData: StoryData) => {
  state.filtersMigrated = false;

  // We do not use selectors here because this action is called before
  // the state has been initialized.
  if (
    storyData.dataSource?.hasOwnProperty('dataSourceList') ||
    storyData.dataSource?.hasOwnProperty('filterParameterConfigurations')
  ) {
    const dataSource = (storyData.dataSource ?? {}) as ReportDataSource;
    state.dataSourceList = dataSource.dataSourceList ?? [];
    state.filterParameterConfigurations = dataSource.filterParameterConfigurations ?? [];
  }
};

export default dataSourceReducer;
